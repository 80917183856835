import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import TileLayer from 'ol/layer/Tile';
import View from 'ol/View';

import {Control, defaults as defaultControls} from 'ol/control';

class LocateMeButton extends Control {
  /**
   * @param {Object} [opt_options] Control options.
   */
  constructor(opt_options) {
    const options = opt_options || {className:'', handler: void(0)};

    const button = document.createElement('button');
    button.innerHTML = '<i class="fg fg-location-poi fg-lg fg-gray "   ></i> ';
    
    const element = document.createElement('div');
    element.className = 'ol-unselectable ol-control ol-control-bhu ' +options.className;
    element.appendChild(button);

    super({
      element: element,
      target: options.target,
    });

    button.addEventListener('click', options.handler.bind(options.digitizer), false);
  }

}


export {LocateMeButton};