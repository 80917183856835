import { jsPanel } from "jspanel4";
import { CONSTANTS } from "./Constants";
import { coordinateDistance, titleCase } from "./utils";
import Centroid from "jsts/org/locationtech/jts/algorithm/Centroid";
import Coordinate from "jsts/org/locationtech/jts/geom/Coordinate";
import LineSegment from "jsts/org/locationtech/jts/geom/LineSegment";
import RelateOp from "jsts/org/locationtech/jts/operation/relate/RelateOp";
import DistanceOp from "jsts/org/locationtech/jts/operation/distance/DistanceOp";
import UnionOp from "jsts/org/locationtech/jts/operation/union/UnionOp";
import Polygonizer from "jsts/org/locationtech/jts/operation/polygonize/Polygonizer";
import InteriorPointArea from "jsts/org/locationtech/jts/algorithm/InteriorPointArea";
import template_area_division from "../assets/templates/_area_division.html?url";

class AreaDivision {
  constructor(digitizer) {
    this.areaDivisionPanel = null;
    this.digitizer = digitizer;
    this.options = digitizer.options;

    this.servicePathPanel = this.options.service_path + "assets/templates/";

    this.segmentCoords = null;
    this.polygonData = null;

    jsPanel.ziBase = 101;
  }

  loadUI() {
    var self = this;
    if (self.areaDivisionPanel) {
      //not required
    } else {
      self.areaDivisionPanel = jsPanel.create({
        closeOnEscape: true,
        headerTitle: "Area Point",
        footerToolbar:
          '<button id="_area_division_try_division" class="btn btn-primary cardAction-btn">Try Division</button>&nbsp;<button id="_area_division_close" class="btn btn-secondary cardAction-btn">Close</button>',
        contentFetch: {
          resource: template_area_division,
          done: function (response, panel) {
            panel.contentRemove();
            panel.content.append(jsPanel.strToHtml(response));

            document.getElementById("_area_division_select_segment").addEventListener("click", function (evt) {
              self.segmentCoords = null;
              self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.SELECT_SEGMENT, {
                event_source: "AREA_DIVISION",
                on_select: function (data) {
                  console.log(data);
                  if (data.coords.length === 2) {
                    document.getElementById("_area_division_segment_selected").classList.remove("fg-cancel-operation");
                    document.getElementById("_area_division_segment_selected").classList.remove("fg-red");
                    document.getElementById("_area_division_segment_selected").classList.add("fg-accept-operation");
                    document.getElementById("_area_division_segment_selected").classList.add("fg-green");
                    self.segmentCoords = data.coords;
                  }
                },
              });
            });

            document.getElementById("_area_division_select_polygon").addEventListener("click", function (evt) {
              self.polygonData = null;
              self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.SELECT_CURRENT_LAYER_FEATURE, {
                layer_code: self.getSelectedLayerCode(),
                event_source: "AREA_DIVISION",
                on_select: function (data) {
                  console.log(data);
                  if (data) {
                    document.getElementById("_area_division_polygon_selected").classList.remove("fg-cancel-operation");
                    document.getElementById("_area_division_polygon_selected").classList.remove("fg-red");
                    document.getElementById("_area_division_polygon_selected").classList.add("fg-accept-operation");
                    document.getElementById("_area_division_polygon_selected").classList.add("fg-green");

                    self.polygonData = self.digitizer.getDataById("polygons", data.id).data;
                  }
                },
              });
            });

            document.getElementById("_area_division_type").addEventListener("change", function (event) {
              console.log(event.target.value);
              if (event.target.value === "3") {
                document.getElementById("_area_division_select_label").innerText = "Select Point";
              } else {
                document.getElementById("_area_division_select_label").innerText = "Select Segment";
              }
            });

            document.getElementById("_area_division_try_division").addEventListener("click", function (ev) {
              if (document.getElementById("_area_division_type").value === "1") {
                self.tryParallerAreaDivision();
              } else if (document.getElementById("_area_division_type").value === "2") {
                self.tryPerpendicularAreaDivision();
              } else if (document.getElementById("_area_division_type").value === "10" || document.getElementById("_area_division_type").value === "11") {
                self.tryHorizondalDivision();
              } else if (document.getElementById("_area_division_type").value === "12" || document.getElementById("_area_division_type").value === "13") {
                self.tryVerticalDivision();
              } else if (document.getElementById("_area_division_type").value === "14") {
                self.tryTopRightDivision();
              } else if (document.getElementById("_area_division_type").value === "15") {
                self.tryTopLeftDivision();
              } else if (document.getElementById("_area_division_type").value === "16") {
                self.tryBottomRightDivision();
              } else if (document.getElementById("_area_division_type").value === "17") {
                self.tryBottomLeftDivision();
              }
            });

            document.getElementById("_area_division_type").addEventListener("change", function (event) {
              switch (event.target.value) {
                case "1":
                case "2":
                  document.getElementById("_area_division_select_segment_tr").classList.remove("DN");
                  break;
                case "11":
                case "12":
                case "13":
                case "14":
                case "15":
                case "16":
                case "17":
                  document.getElementById("_area_division_select_segment_tr").classList.add("DN");
                  break;
                default:
                // code block
              }
            });

            document.getElementById("_area_division_close").addEventListener("click", function (evt) {
              self.areaDivisionPanel.close();
            });
            self.showMessage("");
            document.getElementById("_area_division_select_segment_tr").classList.add("DN");
          },
        },

        theme: "dark ",
        container: "div.map",
        borderRadius: ".5rem",
        position: "center-top",
        contentSize: {
          width: () => window.innerWidth * 0.3,
          height: "20vh",
        },
        headerControls: { size: "xs" }, // shorthand
        onclosed: function (panel, closedByUser) {
          self.digitizer.interactionManager.removeCurrentInteraction();
          self.digitizer.clearTempGeom();
          self.areaDivisionPanel = null;
          this.areaDivisionPanel = null;
        },
      });
    }
  }

  showMessage(msg) {
    document.getElementById("_area_division_msg").innerText = msg;
    if (!msg || msg === "") {
      document.getElementById("_area_division_msg").classList.add("DN");
    } else {
      document.getElementById("_area_division_msg").classList.remove("DN");
    }
  }

  getRequiredArea() {
    let area = Number(document.getElementById("_area_division_area").value);
    if (document.getElementById("_area_division_unit_type").value === "1") {
      let polygonArea = Number(this.digitizer._jstsReader.read(this.polygonData.geom).getArea().toFixed(2));
      return Number((polygonArea * (area / 100)).toFixed(2));
    } else {
      return Number(area);
    }
  }

  getSelectedLayerCode() {
   return this.digitizer.uiBuilder.getSelectedLayerCode();
  }

  async tryHorizondalDivision() {
    const self = this;
    let splitterLine = null;
    let undoItemGroup = [];
    let rarea = this.getRequiredArea();
    let curGeom = this.digitizer._jstsReader.read(this.polygonData.geom);
    let area = Number(curGeom.getArea().toFixed(2));
    self.showMessage("");
    if (area < rarea) {
      self.showMessage("Required area more than area of polygon");
      return;
    }

    const polygon = this.digitizer._jstsReader.read(this.polygonData.geom);
    const env = polygon.getEnvelopeInternal();
    this.digitizer.clearTempGeom();

    let cnt = 0;
    let xmin = env.getMinX();
    let xmax = env.getMaxX();
    let ymax = env.getMaxY();
    let ymin = env.getMinY();
    let north = document.getElementById("_area_division_type").value === "10" ? true : false;
    while (cnt < 1000) {
      cnt++;

      let y1i = ymin + (ymax - ymin) / 2;

      let cord1 = new Coordinate(xmin, y1i);
      let cord2 = new Coordinate(xmax, y1i);
      let splitSegment = new LineSegment(cord1, cord2);

      let splitterLine = splitSegment.toGeometry(this.digitizer._geometryFactory);
      let splitResults = self.splitPolygon(this.polygonData, splitterLine);

      if (splitResults.length === 2) {
        let interestedPoly = this.digitizer._jstsReader.read(splitResults[0].geom);
        let poly2 = this.digitizer._jstsReader.read(splitResults[1].geom);
        let miny = interestedPoly.getEnvelopeInternal().getMinY();
        let miny2 = poly2.getEnvelopeInternal().getMinY();

        if (north) {
          if (miny < miny2) {
            interestedPoly = poly2;
          }
        } else {
          if (miny > miny2) {
            interestedPoly = poly2;
          }
        }

        area = Number(interestedPoly.getArea().toFixed(2));
        if (rarea == area) {
          for (let i = 0; i < splitResults.length; i++) {
            self.digitizer.addPolygonObject(splitResults[i], undoItemGroup);
          }
          self.digitizer.deletePolygonByID(self.polygonData.id, undoItemGroup);
          self.digitizer.undoRedoManager.pushToUndoStack(undoItemGroup);
          return;
        } else if (north) {
          if (rarea < area) {
            ymin = y1i;
          } else if (rarea > area) {
            ymax = y1i;
          }
        } else {
          if (rarea > area) {
            ymin = y1i;
          } else if (rarea < area) {
            ymax = y1i;
          }
        }
      }
    }
    self.showMessage("Unable to calculate division line");
    return;
  }

  async tryVerticalDivision() {
    const self = this;
    let splitterLine = null;
    let undoItemGroup = [];
    let rarea = this.getRequiredArea();
    let curGeom = this.digitizer._jstsReader.read(this.polygonData.geom);
    let area = Number(curGeom.getArea().toFixed(2));
    self.showMessage("");
    if (area < rarea) {
      self.showMessage("Required area more than area of polygon");
      return;
    }

    const polygon = this.digitizer._jstsReader.read(this.polygonData.geom);
    const env = polygon.getEnvelopeInternal();
    this.digitizer.clearTempGeom();

    let cnt = 0;
    let xmin = env.getMinX();
    let xmax = env.getMaxX();
    let ymax = env.getMaxY();
    let ymin = env.getMinY();
    let west = document.getElementById("_area_division_type").value === "12" ? true : false;
    while (cnt < 1000) {
      cnt++;

      let x1i = xmin + (xmax - xmin) / 2;

      let cord1 = new Coordinate(x1i, ymin);
      let cord2 = new Coordinate(x1i, ymax);
      let splitSegment = new LineSegment(cord1, cord2);

      let splitterLine = splitSegment.toGeometry(this.digitizer._geometryFactory);
      let splitResults = self.splitPolygon(this.polygonData, splitterLine);

      if (splitResults.length === 2) {
        let interestedPoly = this.digitizer._jstsReader.read(splitResults[0].geom);
        let poly2 = this.digitizer._jstsReader.read(splitResults[1].geom);
        let minx = interestedPoly.getEnvelopeInternal().getMinX();
        let minx2 = poly2.getEnvelopeInternal().getMinX();

        if (west) {
          if (minx < minx2) {
            interestedPoly = poly2;
          }
        } else {
          if (minx > minx2) {
            interestedPoly = poly2;
          }
        }

        area = Number(interestedPoly.getArea().toFixed(2));
        if (rarea == area) {
          for (let i = 0; i < splitResults.length; i++) {
            self.digitizer.addPolygonObject(splitResults[i], undoItemGroup);
          }
          self.digitizer.deletePolygonByID(self.polygonData.id, undoItemGroup);
          self.digitizer.undoRedoManager.pushToUndoStack(undoItemGroup);
          return;
        } else if (west) {
          if (rarea < area) {
            xmin = x1i;
          } else if (rarea > area) {
            xmax = x1i;
          }
        } else {
          if (rarea > area) {
            xmin = x1i;
          } else if (rarea < area) {
            xmax = x1i;
          }
        }
      }
    }
    self.showMessage("Unable to calculate division line");
    return;
  }
  async tryTopLeftDivision() {
    const self = this;
    let splitterLine = null;
    let undoItemGroup = [];
    let rarea = this.getRequiredArea();
    let curGeom = this.digitizer._jstsReader.read(this.polygonData.geom);
    let area = Number(curGeom.getArea().toFixed(2));
    self.showMessage("");
    if (area < rarea) {
      self.showMessage("Required area more than area of polygon");
      return;
    }

    const polygon = this.digitizer._jstsReader.read(this.polygonData.geom);
    const env = polygon.getEnvelopeInternal();
    this.digitizer.clearTempGeom();

    let cnt = 0;
    let xmin = env.getMinX();
    let xmax = env.getMaxX();
    let ymax = env.getMaxY();
    let ymin = env.getMinY();

    let ymaxFinal = ymax;
    let xminFinal = xmin;
    while (cnt < 1000) {
      cnt++;

      let x1i = xmin + (xmax - xmin) / 2;
      let y1i = ymin + (ymax - ymin) / 2;

      let cord1 = new Coordinate(x1i, ymaxFinal);
      let cord2 = new Coordinate(x1i, y1i);
      let cord3 = new Coordinate(xminFinal, y1i);

      let splitterLine = self.digitizer._geometryFactory.createLineString([cord1, cord2, cord3]);

      let splitResults = self.splitPolygon(this.polygonData, splitterLine);

      if (splitResults.length === 2) {
        let interestedPoly = this.digitizer._jstsReader.read(splitResults[0].geom);
        let poly2 = this.digitizer._jstsReader.read(splitResults[1].geom);
        let minx = interestedPoly.getEnvelopeInternal().getMaxX();
        let minx2 = poly2.getEnvelopeInternal().getMaxX();

        if (minx > minx2) {
          interestedPoly = poly2;
        }

        area = Number(interestedPoly.getArea().toFixed(2));
        if (rarea == area) {
          for (let i = 0; i < splitResults.length; i++) {
            self.digitizer.addPolygonObject(splitResults[i], undoItemGroup);
          }
          self.digitizer.deletePolygonByID(self.polygonData.id, undoItemGroup);
          self.digitizer.undoRedoManager.pushToUndoStack(undoItemGroup);
          return;
        } else if (rarea < area) {
          xmax = x1i;
          ymin = y1i;
        } else {
          xmin = x1i;
          ymax = y1i;
        }
      }

      // this.digitizer.showTempGeomWkt(
      //   this.digitizer._jstsWriter.write(splitterLine),
      //   "",
      //   "",
      //   "",
      //   { stroke_color: "rgba(100, 0, 0, 0.7)" }
      // );

      // await new Promise((r) => setTimeout(r, 2000));
    }
    self.showMessage("Unable to calculate division line");
    return;
  }

  async tryTopRightDivision() {
    const self = this;
    let splitterLine = null;
    let undoItemGroup = [];
    let rarea = this.getRequiredArea();
    let curGeom = this.digitizer._jstsReader.read(this.polygonData.geom);
    let area = Number(curGeom.getArea().toFixed(2));
    self.showMessage("");
    if (area < rarea) {
      self.showMessage("Required area more than area of polygon");
      return;
    }

    const polygon = this.digitizer._jstsReader.read(this.polygonData.geom);
    const env = polygon.getEnvelopeInternal();
    this.digitizer.clearTempGeom();

    let cnt = 0;
    let xmin = env.getMinX();
    let xmax = env.getMaxX();
    let ymax = env.getMaxY();
    let ymin = env.getMinY();

    let ymaxFinal = ymax;
    let xmaxFinal = xmax;
    while (cnt < 1000) {
      cnt++;

      let x1i = xmin + (xmax - xmin) / 2;
      let y1i = ymin + (ymax - ymin) / 2;

      let cord1 = new Coordinate(x1i, ymaxFinal);
      let cord2 = new Coordinate(x1i, y1i);
      let cord3 = new Coordinate(xmaxFinal, y1i);

      let splitterLine = self.digitizer._geometryFactory.createLineString([cord1, cord2, cord3]);

      let splitResults = self.splitPolygon(this.polygonData, splitterLine);

      if (splitResults.length === 2) {
        let interestedPoly = this.digitizer._jstsReader.read(splitResults[0].geom);
        let poly2 = this.digitizer._jstsReader.read(splitResults[1].geom);
        let minx = interestedPoly.getEnvelopeInternal().getMinX();
        let minx2 = poly2.getEnvelopeInternal().getMinX();

        if (minx < minx2) {
          interestedPoly = poly2;
        }

        area = Number(interestedPoly.getArea().toFixed(2));
        if (rarea == area) {
          for (let i = 0; i < splitResults.length; i++) {
            self.digitizer.addPolygonObject(splitResults[i], undoItemGroup);
          }
          self.digitizer.deletePolygonByID(self.polygonData.id, undoItemGroup);
          self.digitizer.undoRedoManager.pushToUndoStack(undoItemGroup);
          return;
        } else if (rarea < area) {
          xmin = x1i;
          ymin = y1i;
        } else {
          xmax = x1i;
          ymax = y1i;
        }
      }

      // this.digitizer.showTempGeomWkt(
      //   this.digitizer._jstsWriter.write(splitterLine),
      //   "",
      //   "",
      //   "",
      //   { stroke_color: "rgba(100, 0, 0, 0.7)" }
      // );

      // await new Promise((r) => setTimeout(r, 2000));
    }
    self.showMessage("Unable to calculate division line");
    return;
  }

  async tryBottomLeftDivision() {
    const self = this;
    let splitterLine = null;
    let undoItemGroup = [];
    let rarea = this.getRequiredArea();
    let curGeom = this.digitizer._jstsReader.read(this.polygonData.geom);
    let area = Number(curGeom.getArea().toFixed(2));
    self.showMessage("");
    if (area < rarea) {
      self.showMessage("Required area more than area of polygon");
      return;
    }

    const polygon = this.digitizer._jstsReader.read(this.polygonData.geom);
    const env = polygon.getEnvelopeInternal();
    this.digitizer.clearTempGeom();

    let cnt = 0;
    let xmin = env.getMinX();
    let xmax = env.getMaxX();
    let ymax = env.getMaxY();
    let ymin = env.getMinY();

    let yminFinal = ymin;
    let xminFinal = xmin;
    while (cnt < 1000) {
      cnt++;

      let y1i = ymin + (ymax - ymin) / 2;
      let x1i = xmin + (xmax - xmin) / 2;

      let cord1 = new Coordinate(x1i, yminFinal);
      let cord2 = new Coordinate(x1i, y1i);
      let cord3 = new Coordinate(xminFinal, y1i);

      let splitterLine = self.digitizer._geometryFactory.createLineString([cord1, cord2, cord3]);

      let splitResults = self.splitPolygon(this.polygonData, splitterLine);

      if (splitResults.length === 2) {
        let interestedPoly = this.digitizer._jstsReader.read(splitResults[0].geom);
        let poly2 = this.digitizer._jstsReader.read(splitResults[1].geom);
        let minx = interestedPoly.getEnvelopeInternal().getMaxX();
        let minx2 = poly2.getEnvelopeInternal().getMaxX();

        if (minx > minx2) {
          interestedPoly = poly2;
        }

        area = Number(interestedPoly.getArea().toFixed(2));
        if (rarea == area) {
          for (let i = 0; i < splitResults.length; i++) {
            self.digitizer.addPolygonObject(splitResults[i], undoItemGroup);
          }
          self.digitizer.deletePolygonByID(self.polygonData.id, undoItemGroup);
          self.digitizer.undoRedoManager.pushToUndoStack(undoItemGroup);
          return;
        } else if (rarea < area) {
          xmax = x1i;
          ymax = y1i;
        } else {
          xmin = x1i;
          ymin = y1i;
        }
      }

      // this.digitizer.showTempGeomWkt(
      //   this.digitizer._jstsWriter.write(splitterLine),
      //   "",
      //   "",
      //   "",
      //   { stroke_color: "rgba(100, 0, 0, 0.7)" }
      // );

      // await new Promise((r) => setTimeout(r, 2000));
    }
    self.showMessage("Unable to calculate division line");
    return;
  }

  async tryBottomRightDivision() {
    const self = this;
    let splitterLine = null;
    let undoItemGroup = [];
    let rarea = this.getRequiredArea();
    let curGeom = this.digitizer._jstsReader.read(this.polygonData.geom);
    let area = Number(curGeom.getArea().toFixed(2));
    self.showMessage("");
    if (area < rarea) {
      self.showMessage("Required area more than area of polygon");
      return;
    }

    const polygon = this.digitizer._jstsReader.read(this.polygonData.geom);
    const env = polygon.getEnvelopeInternal();
    this.digitizer.clearTempGeom();

    let cnt = 0;
    let xmin = env.getMinX();
    let xmax = env.getMaxX();
    let ymax = env.getMaxY();
    let ymin = env.getMinY();

    let yminFinal = ymin;
    let xmaxFinal = xmax;
    while (cnt < 1000) {
      cnt++;

      let y1i = ymin + (ymax - ymin) / 2;
      let x1i = xmin + (xmax - xmin) / 2;

      let cord1 = new Coordinate(x1i, yminFinal);
      let cord2 = new Coordinate(x1i, y1i);
      let cord3 = new Coordinate(xmaxFinal, y1i);

      let splitterLine = self.digitizer._geometryFactory.createLineString([cord1, cord2, cord3]);

      let splitResults = self.splitPolygon(this.polygonData, splitterLine);

      if (splitResults.length === 2) {
        let interestedPoly = this.digitizer._jstsReader.read(splitResults[0].geom);
        let poly2 = this.digitizer._jstsReader.read(splitResults[1].geom);
        let minx = interestedPoly.getEnvelopeInternal().getMinX();
        let minx2 = poly2.getEnvelopeInternal().getMinX();

        if (minx < minx2) {
          interestedPoly = poly2;
        }

        area = Number(interestedPoly.getArea().toFixed(2));
        if (rarea == area) {
          for (let i = 0; i < splitResults.length; i++) {
            self.digitizer.addPolygonObject(splitResults[i], undoItemGroup);
          }
          self.digitizer.deletePolygonByID(self.polygonData.id, undoItemGroup);
          self.digitizer.undoRedoManager.pushToUndoStack(undoItemGroup);
          return;
        } else if (rarea < area) {
          xmin = x1i;
          ymax = y1i;
        } else {
          xmax = x1i;
          ymin = y1i;
        }
      }

      // this.digitizer.showTempGeomWkt(
      //   this.digitizer._jstsWriter.write(splitterLine),
      //   "",
      //   "",
      //   "",
      //   { stroke_color: "rgba(100, 0, 0, 0.7)" }
      // );

      // await new Promise((r) => setTimeout(r, 2000));
    }
    self.showMessage("Unable to calculate division line");
    return;
  }

  tryParallerAreaDivision() {
    const self = this;
    let splitterLine = null;
    let undoItemGroup = [];
    let rarea = this.getRequiredArea();
    let curGeom = this.digitizer._jstsReader.read(this.polygonData.geom);
    let area = Number(curGeom.getArea().toFixed(2));
    self.showMessage("");
    if (area < rarea) {
      self.showMessage("Required area more than area of polygon");
      return;
    }
    const polygon = this.digitizer._jstsReader.read(this.polygonData.geom);
    const env = polygon.getEnvelope();
    console.log(this.digitizer._jstsWriter.write(polygon.getBoundary()));

    this.digitizer.clearTempGeom();

    const polyCenter = Centroid.getCentroid(env);

    const segment = new LineSegment(this.segmentCoords[0][0], this.segmentCoords[0][1], this.segmentCoords[1][0], this.segmentCoords[1][1]);
    const referenceLine = segment.toGeometry(this.digitizer._geometryFactory);
    let perpDistance = segment.distancePerpendicular(polyCenter);

    let cord1 = segment.pointAlongOffset(0, perpDistance);
    let cord2 = segment.pointAlongOffset(1, perpDistance);
    let midSegment = new LineSegment(cord1, cord2);
    if (!RelateOp.intersects(polygon, midSegment.toGeometry(this.digitizer._geometryFactory))) {
      perpDistance = -1 * perpDistance;
      cord1 = segment.pointAlongOffset(0, perpDistance);
      cord2 = segment.pointAlongOffset(1, perpDistance);
      midSegment = new LineSegment(cord1, cord2);
    }

    const midOffset = perpDistance;
    let fullOffset = perpDistance * env.getLength();

    let corners = env.getCoordinates();
    for (let i = 0; i < corners.length - 1; i++) {
      let l1 = new LineSegment(corners[i], corners[i + 1]);
      let li = midSegment.lineIntersection(l1);

      if (li) {
        let dn = DistanceOp.distance(this.digitizer._geometryFactory.createPoint(li), l1.toGeometry(this.digitizer._geometryFactory));
        if (dn === 0) {
          if (midSegment.getCoordinate(0).distance(li) < midSegment.getCoordinate(1).distance(li)) {
            midSegment.setCoordinates(li, midSegment.getCoordinate(1));
          } else {
            midSegment.setCoordinates(midSegment.getCoordinate(0), li);
          }
        }
      }
    }

    cord1 = midSegment.pointAlongOffset(0, midOffset * -1);
    cord2 = midSegment.pointAlongOffset(1, midOffset * -1);

    let baseSegment = new LineSegment(cord1, cord2);
    cord1 = baseSegment.pointAlong(-2);
    cord2 = baseSegment.pointAlong(2);
    baseSegment = new LineSegment(cord1, cord2);

    cord1 = baseSegment.pointAlongOffset(0, fullOffset);
    cord2 = baseSegment.pointAlongOffset(1, fullOffset);
    let topSegment = new LineSegment(cord1, cord2);

    let dn = DistanceOp.distance(polygon, topSegment.toGeometry(this.digitizer._geometryFactory));
    if (fullOffset < 0) {
      fullOffset = fullOffset + dn;
    } else {
      fullOffset = fullOffset - dn;
    }
    cord1 = baseSegment.pointAlongOffset(0, fullOffset);
    cord2 = baseSegment.pointAlongOffset(1, fullOffset);
    topSegment = new LineSegment(cord1, cord2);

    // this.digitizer.showTempGeomWkt(this.digitizer._jstsWriter.write(baseSegment.toGeometry(this.digitizer._geometryFactory)));
    // this.digitizer.showTempGeomWkt(this.digitizer._jstsWriter.write(midSegment.toGeometry(this.digitizer._geometryFactory)));
    // this.digitizer.showTempGeomWkt(this.digitizer._jstsWriter.write(topSegment.toGeometry(this.digitizer._geometryFactory)));

    // return;

    let splitOffset = fullOffset;
    let fraction = 0.5;
    splitOffset = splitOffset * fraction;

    cord1 = baseSegment.pointAlongOffset(0, splitOffset);
    cord2 = baseSegment.pointAlongOffset(1, splitOffset);
    let splitSegment = new LineSegment(cord1, cord2);

    let lb = 0;
    let ub = Math.abs(fullOffset);
    let sign = fullOffset < 0 ? -1 : 1;
    splitOffset = (ub - lb) / 2;

    // console.log("rarea: "+rarea+", area: "+area+", lb: "+lb+", ub: "+ub+", splitOffset: "+splitOffset);
    let cnt = 0;
    while (cnt < 1000) {
      cnt++;

      console.log("" + cnt + "  rarea: " + rarea + ", area: " + area + ", lb: " + lb + ", ub: " + ub + ", splitOffset: " + splitOffset);

      cord1 = baseSegment.pointAlongOffset(0, splitOffset * sign);
      cord2 = baseSegment.pointAlongOffset(1, splitOffset * sign);
      let splitSegment = new LineSegment(cord1, cord2);
      splitterLine = splitSegment.toGeometry(this.digitizer._geometryFactory);
      let splitResults = self.splitPolygon(this.polygonData, splitterLine);
      if (splitResults.length === 2) {
        let interestedPoly = null;
        for (let i = 0; i < splitResults.length; i++) {
          let poly = this.digitizer._jstsReader.read(splitResults[i].geom);
          if (DistanceOp.distance(poly, referenceLine) === 0) {
            interestedPoly = poly;
          }
        }
        area = Number(interestedPoly.getArea().toFixed(2));

        if (area === rarea) {
          for (let i = 0; i < splitResults.length; i++) {
            self.digitizer.addPolygonObject(splitResults[i], undoItemGroup);
          }
          self.digitizer.deletePolygonByID(self.polygonData.id, undoItemGroup);
          self.digitizer.undoRedoManager.pushToUndoStack(undoItemGroup);
          return;
        } else if (area > rarea) {
          ub = splitOffset;
          splitOffset = splitOffset / 2;
        } else {
          // lb = splitOffset;
          splitOffset = splitOffset + (ub - splitOffset) / 2;
        }
      }
      // else{
      //     console.log("Not possible");
      //     return;
      // }
    }
    self.showMessage("Unable to calculate division line");
    return;
  }

  async tryPerpendicularAreaDivision() {
    const self = this;
    let splitterLine = null;
    let undoItemGroup = [];
    let rarea = this.getRequiredArea();
    let curGeom = this.digitizer._jstsReader.read(this.polygonData.geom);
    let area = Number(curGeom.getArea().toFixed(2));
    self.showMessage("");
    if (area < rarea) {
      self.showMessage("Required area more than area of polygon");
      return;
    }
    const polygon = this.digitizer._jstsReader.read(this.polygonData.geom);
    const env = polygon.getEnvelope();
    console.log(this.digitizer._jstsWriter.write(polygon.getBoundary()));

    this.digitizer.clearTempGeom();

    const polyCenter = Centroid.getCentroid(env);

    const segment = new LineSegment(this.segmentCoords[0][0], this.segmentCoords[0][1], this.segmentCoords[1][0], this.segmentCoords[1][1]);
    const referenceLine = segment.toGeometry(this.digitizer._geometryFactory);
    let perpDistance = segment.distancePerpendicular(polyCenter);

    let cord1 = segment.pointAlongOffset(0.5, polygon.getLength());
    let cord2 = segment.pointAlongOffset(0.5, -1 * polygon.getLength());
    let midSegment = new LineSegment(cord1, cord2);

    let corners = env.getCoordinates();
    let coords = [];
    for (let i = 0; i < corners.length - 1; i++) {
      let l1 = new LineSegment(corners[i], corners[i + 1]);
      let li = midSegment.lineIntersection(l1);

      if (li) {
        let dn = DistanceOp.distance(env, self.digitizer._geometryFactory.createPoint(li));
        if (dn === 0) {
          coords.push(li);
        }
      }
    }
    console.log(coords);
    midSegment = new LineSegment(coords[0], coords[1]); //.setCoordinates(0,coords[0]);
    // midSegment.setCoordinates(1,coords[1]);

    cord1 = midSegment.pointAlong(polygon.getLength() / midSegment.getLength());
    cord2 = midSegment.pointAlong((-1 * polygon.getLength()) / midSegment.getLength());
    midSegment = new LineSegment(cord1, cord2);

    corners = polygon.getCoordinates();
    let lb = 0;
    let ub = 0;
    for (let i = 0; i < corners.length - 1; i++) {
      if (midSegment.orientationIndex(corners[i]) < 0) {
        let d = midSegment.distance(corners[i]);
        if (d > lb) lb = d;
      } else {
        let d = midSegment.distance(corners[i]);
        if (d > ub) ub = d;
      }

      let l1 = new LineSegment(corners[i], corners[i + 1]);
      let li = midSegment.lineIntersection(l1);

      if (li) {
        let dn = DistanceOp.distance(env, self.digitizer._geometryFactory.createPoint(li));
        if (dn === 0) {
          coords.push(li);
        }
      }
    }
    let sign = -1;
    // ub = lb + ub;
    // lb = 0;

    cord1 = midSegment.pointAlongOffset(0, lb * -1);
    cord2 = midSegment.pointAlongOffset(1, lb * -1);
    let baseSegment = new LineSegment(cord1, cord2);

    cord1 = midSegment.pointAlongOffset(0, ub);
    cord2 = midSegment.pointAlongOffset(1, ub);
    let ubSegment = new LineSegment(cord1, cord2);

    if (segment.distance(ubSegment) < segment.distance(baseSegment)) {
      let temp = baseSegment;
      baseSegment = ubSegment;
      ubSegment = temp;
    }

    // cord1 = midSegment.pointAlongOffset(0, ub);
    sign = baseSegment.orientationIndex(ubSegment.getCoordinate(0));

    this.digitizer.showTempGeomWkt(this.digitizer._jstsWriter.write(baseSegment.toGeometry(this.digitizer._geometryFactory)));

    this.digitizer.showTempGeomWkt(this.digitizer._jstsWriter.write(ubSegment.toGeometry(this.digitizer._geometryFactory)));
    ub = baseSegment.distance(ubSegment);
    lb = 0;
    let splitOffset = (ub - lb) / 2;
    // this.digitizer.clearTempGeom(0);
    let cnt = 0;
    while (cnt < 500) {
      cnt++;

      cord1 = baseSegment.pointAlongOffset(0, splitOffset * sign);
      cord2 = baseSegment.pointAlongOffset(1, splitOffset * sign);
      let splitSegment = new LineSegment(cord1, cord2);
      splitterLine = splitSegment.toGeometry(this.digitizer._geometryFactory);

      let splitResults = self.splitPolygon(this.polygonData, splitterLine);
      if (splitResults.length === 2) {
        let interestedPoly = null;
        let poly1 = this.digitizer._jstsReader.read(splitResults[0].geom);
        let poly2 = this.digitizer._jstsReader.read(splitResults[1].geom);
        let bLine = baseSegment.toGeometry(this.digitizer._geometryFactory);
        if (DistanceOp.distance(poly1, bLine) < DistanceOp.distance(poly2, bLine)) {
          interestedPoly = poly1;
        } else {
          interestedPoly = poly2;
        }

        // for (let i = 0; i < splitResults.length; i++) {
        //   let poly = this.digitizer._jstsReader.read(splitResults[i].geom);
        //   if (
        //     DistanceOp.distance(
        //       poly,
        //       baseSegment.toGeometry(this.digitizer._geometryFactory)
        //     ) === 0
        //   ) {
        //     interestedPoly = poly;
        //   }
        // }
        // interestedPoly = this.digitizer._jstsReader.read(splitResults[0].geom);
        area = Number(interestedPoly.getArea().toFixed(2));
        // this.digitizer.showTempGeomWkt(
        //   this.digitizer._jstsWriter.write(
        //     segment.toGeometry(this.digitizer._geometryFactory)
        //   )
        // );

        if (area === rarea) {
          for (let i = 0; i < splitResults.length; i++) {
            self.digitizer.addPolygonObject(splitResults[i], undoItemGroup);
          }
          self.digitizer.deletePolygonByID(self.polygonData.id, undoItemGroup);
          self.digitizer.undoRedoManager.pushToUndoStack(undoItemGroup);
          return;
        } else if (area > rarea) {
          ub = splitOffset;
          splitOffset = splitOffset / 2;
        } else {
          // lb = splitOffset;
          splitOffset = splitOffset + (ub - splitOffset) / 2;
        }
      }

      console.log("" + cnt + "  rarea: " + rarea + ", area: " + area + ", lb: " + lb + ", ub: " + ub + ", splitOffset: " + splitOffset);

      // this.digitizer.showTempGeomWkt(
      //   this.digitizer._jstsWriter.write(
      //     splitSegment.toGeometry(this.digitizer._geometryFactory)
      //   ),
      //   "",
      //   "",
      //   "",
      //   { stroke_color: "rgba(100, 0, 0, 0.7)" }
      // );

      // await new Promise((r) => setTimeout(r, 2000));
      // else{
      //     console.log("Not possible");
      //     return;
      // }
    }
    self.showMessage("Unable to calculate division line");
    return;
  }

  splitPolygon(initialPolygonData, splitterLineJSTS) {
    const splittedPolygons = [];
    var self = this;
    let splitted = false;
    var a;
    var ring;
    var b;
    let factory = self.digitizer._geometryFactory;

    let boundaryArray = [];

    if (initialPolygonData) {
      a = self.digitizer._jstsReader.read(initialPolygonData.geom);
      b = a.getGeometryN(0).getBoundary();
      boundaryArray.push(a);
      ring = b;
    }

    var union = ring;
    union = UnionOp.union(union, splitterLineJSTS);

    if (union) {
      var polygonizer = new Polygonizer();
      polygonizer.add(union);

      var polygons = polygonizer.getPolygons();

      for (var i = polygons.iterator(); i.hasNext(); ) {
        var polygon = i.next();
        for (var ind = 0; ind < boundaryArray.length; ind++) {
          if (RelateOp.contains(boundaryArray[ind], self.digitizer._geometryFactory.createPoint(InteriorPointArea.getInteriorPoint(polygon)))) {
            if (polygon.getArea() > 1) {
              splitted = true;

              let polyData = {
                name: "P" + (self.digitizer.generatedData.polygons.length + 1),
                geom: self.digitizer._jstsWriter.write(polygon),
                __area: Number(polygon.getArea().toFixed(2)),
              };
              if ("attrs" in initialPolygonData) {
                polyData.attrs = initialPolygonData.attrs;
              }
              splittedPolygons.push(polyData);
            }
          }
        }
      }
    }
    return splittedPolygons;
  }
}

export { AreaDivision };
