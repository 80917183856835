import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import TileLayer from 'ol/layer/Tile';
import View from 'ol/View';

import {Control, defaults as defaultControls} from 'ol/control';

class AcceptCancelButton extends Control {
  /**
   * @param {Object} [opt_options] Control options.
   */
  constructor(opt_options) {
    const options = opt_options || {className:'', accept: void(0), cancel: void(0)};

    
    const button = document.createElement('button');
    button.innerHTML = '<i class="fg fg-cancel-operation fg-lg fg-gray "   ></i> ';

    const buttonAccept = document.createElement('button');
    buttonAccept.innerHTML = '<i class="fg fg-accept-operation fg-lg fg-gray "   ></i> ';

    const element = document.createElement('div');
    element.className = 'ol-unselectable ol-control  __bn-control-accept-cancel' ;
    element.appendChild(button);

    element.appendChild(buttonAccept);

    super({
      element: element,
      target: options.target,
    });

    button.addEventListener('click', options.cancel, false);
    buttonAccept.addEventListener('click', options.accept, false);
  }

}


export {AcceptCancelButton};