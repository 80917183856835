import "../../assets/styles/bn.css";
import osmLogo from "../../assets/images/osm.png";
import bingLogo from "../../assets/images/bing.png";
import bhuvanLogo from "../../assets/images/bhuvan.png";
import blankImg from "../../assets/images/blank.png";
import template_arc_point from "../../assets/templates/_arcpoint.html?url";
import template_ladder_point from "../../assets/templates/_ladder_table.html?url";
import template_cut_segment from "../../assets/templates/_cutSegment.html?url";
import template_angle_point from "../../assets/templates/_angle_point.html?url";
import template_edit_point from "../../assets/templates/_edit_point.html?url";
import template_ets_point from "../../assets/templates/_point.html?url";
import template_attribute_mapping from "../../assets/templates/_attribute_mapping.html?url";
import template_settings from "../../assets/templates/_settings.html?url";
import template_export_pdf from "../../assets/templates/_export_pdf.html?url";

import { jsPanel } from "jspanel4";
import { CONSTANTS } from "../Constants";
import { coordinateDistance, titleCase } from "../utils";
import { AreaDivision } from "../AreaDivision";
import { jsPDF } from "jspdf/dist/jspdf.umd";
// import Grid from "tui-grid";
import { EditableTable } from "./EditableTable";
import baseui from "../../assets/templates/baseui.html?raw";

class UIBuilder {
  constructor(targetDiv, digitizer) {
    this.panelSettings = null;
    this.digitizer = digitizer;
    this.options = digitizer.options;
    this.gridSpaceing = 10;
    this.targetDiv = targetDiv;
    this.servicePathPanel = this.options.service_path + "assets/templates/";

    jsPanel.ziBase = 101;
    this.selectedClass = "fg-orange";
    this.defaultClass = "fg-white";
  }

  loadUI() {
    const container = document.getElementById(this.targetDiv);
    container.innerHTML = "";
    container.innerHTML = baseui;
  }
  //   loadUI() {
  //     let self = this;
  //     var newStyle = document.createElement("style");
  //     newStyle.appendChild(
  //       document.createTextNode(
  //         "\
  // @font-face {\
  //     font-family: \"font-gis\";\
  //     src: url('_ASSETS_/fonts/font-gis.eot?t=1661164438225');\
  //     src: url('_ASSETS_/fonts/font-gis.eot?#iefix') format('eot'),\
  //       url('_ASSETS_/fonts/font-gis.woff2?t=1661164438225') format('woff2'),\
  //       url('_ASSETS_/fonts/font-gis.woff?t=1661164438225') format('woff'),\
  //       url('_ASSETS_/fonts/font-gis.ttf?t=1661164438225') format('truetype'),\
  //       url('_ASSETS_/fonts/font-gis.svg#font-gis') format('svg');\
  //     font-weight: normal;\
  //     font-style: normal;\
  //   }\
  // ".replaceAll("_ASSETS_", this.options.service_path + "assets")
  //       )
  //     );

  //     document.head.appendChild(newStyle);
  //     fetch(this.options.service_path + "assets/templates/baseui.html?v=" + self.digitizer.version())
  //       .then((data) => {
  //         return data.text();
  //       })
  //       .then((data) => {
  //         data = data.replaceAll("_ASSETS_", this.options.service_path + "assets");

  //         document.getElementById(this.targetDiv).innerHTML = data;
  //         //  this.initGrids();

  //         // this.loadToolBar();
  //         this.digitizer.initMaps();
  //       });
  //   }

  // initGrids() {
  //   var self = this;
  //   var griddiv = document.getElementById("grid");
  //   griddiv.innerHTML = "";

  //   var griddivline = document.getElementById("linesData");
  //   griddivline.innerHTML = "";
  //   this.linegrid = new Grid({
  //     usageStatistics: false,
  //     el: document.getElementById("linesData"),
  //     data: this.digitizer.gridData.lines,
  //     rowHeaders: ["rowNum", "checkbox"],
  //     scrollX: false,
  //     scrollY: false,
  //     columns: [
  //       {
  //         header: "Actions",
  //         name: "actions",
  //       },
  //       {
  //         header: "Name",
  //         name: "name",
  //         // },
  //         // {
  //         //     header: 'Details',
  //         //     name: 'details'
  //       },
  //     ],
  //   });
  //   this.linegrid.on("checkAll", (ev) => {
  //     digitizer.doHighlight(undefined, "lines", true);
  //   });
  //   this.linegrid.on("uncheckAll", (ev) => {
  //     digitizer.doHighlight(undefined, "lines", false);
  //   });
  //   this.linegrid.on("check", (ev) => {
  //     digitizer.doHighlight(this.gridData.lines[ev["rowKey"]].id, "lines", true);
  //   });
  //   this.linegrid.on("uncheck", (ev) => {
  //     digitizer.doHighlight(this.gridData.lines[ev["rowKey"]].id, "lines", false);
  //   });
  //   this.linegrid.on("focusChange", (ev) => {
  //     //console.log('change focused cell!', ev);
  //   });
  //   // this.refreshPolygonsInDataDisplay(true);
  //   //var griddivpoly = document.getElementById('PolygonData');
  //   //griddivpoly.innerHTML = "";

  //   // this.polygrid = new tui.Grid({
  //   // usageStatistics:false,
  //   //     el: document.getElementById('PolygonData'),
  //   //     data: this.gridData.polygon,
  //   //     rowHeaders: ['rowNum', 'checkbox'],
  //   //     scrollX: false,
  //   //     scrollY: false,
  //   //     columns: [
  //   //         {
  //   //             header: 'Actions',
  //   //             name: 'actions'
  //   //         },
  //   //         {
  //   //             header: 'Name',
  //   //             name: 'name'
  //   //         },
  //   //         {
  //   //             header: 'Details',
  //   //             name: 'details'
  //   //         }
  //   //     ]
  //   // });
  //   // this.polygrid.on('checkAll', ev => {
  //   //     this.doHighlight(undefined, 'polygons', true);
  //   // });
  //   // this.polygrid.on('uncheckAll', ev => {
  //   //     this.doHighlight(undefined, 'polygons', false);
  //   // });
  //   // this.polygrid.on('check', ev => {
  //   //     this.doHighlight(this.gridData.polygon[ev["rowKey"]].id, 'polygons', true);
  //   // });
  //   // this.polygrid.on('uncheck', ev => {
  //   //     this.doHighlight(this.gridData.polygon[ev["rowKey"]].id, 'polygons', false);
  //   // });
  //   // this.polygrid.on('focusChange', ev => {
  //   //     //console.log('change focused cell!', ev);
  //   // });

  //   // this.initLayerMaster();

  //   // this.initTools();
  // }

  constructAndOpenAttributePanel(data) {
    var self = this;
    if (self.attributePanel && self.attributePanel !== null) {
      self.attributePanel.close(() => {
        self.attributePanel = null;
      });
    }
    self.attributePanel = jsPanel.create({
      id: "attribute-panel",
      closeOnEscape: true,
      headerTitle: "Attributes",
      footerToolbar: '<button id="doneEditing" class="btn btn-primary cardAction-btn">OK</button>',
      onmaximized: () => {
        self.attributegrid.refreshLayout();
      },
      resizeit: {
        stop: () => {
          self.attributegrid.refreshLayout();
        },
      },
      contentFetch: {
        resource: template_attribute_mapping,
        done: function (response, panel) {
          let panelWrapperID = document.getElementById("attribute-panel");
          panel.contentRemove();
          panel.content.append(jsPanel.strToHtml(response));

          let selectedLayerCode = self.getSelectedLayerCode();
          let toMapLayer = self.digitizer.getLayerMasterDataFromCode(selectedLayerCode);

          // panelWrapperID.querySelector("#doneEditing").addEventListener("click", function () {
          //   self.attributegrid.finishEditing();
          //   let modifiedRows = self.attributegrid.getModifiedRows().updatedRows;
          //   let undoItemGroup = [];
          //   for (const row of modifiedRows) {
          //     let attrs = {};
          //     for (const at of toMapLayer.attrs) {
          //       attrs[at.key] = row[at.key];
          //     }
          //     self.digitizer.editAttributes(self.digitizer.getDataObjectsForGeom(toMapLayer.geom_type), row.id, attrs, undoItemGroup);
          //   }
          //   if (undoItemGroup.length >= 1) {
          //     self.digitizer.undoRedoManager.pushToUndoStack(undoItemGroup);
          //   }
          //   self.attributePanel.close();
          // });

          let attrMeta = toMapLayer.attrs;

          const dataforAttributeGrid = [];
          let columnLabels = {
            id: {
              header: "ID",
              visible: false,
            },
          };
          if (self.digitizer.getDataObjectsForGeom(toMapLayer.geom_type) === CONSTANTS.DATA_OBJECTS.POLYGON) {
            columnLabels["areaSystem"] = {
              header: "Area",
              editable: false,
            };
          } else if (self.digitizer.getDataObjectsForGeom(toMapLayer.geom_type) === CONSTANTS.DATA_OBJECTS.LINE) {
            columnLabels["lengthSystem"] = {
              header: "Length",
              editable: false,
            };
          }
          let dataTemplate = {};
          for (const at of attrMeta) {
            columnLabels[at.key] = {
              header: at.name,
            };
            dataTemplate[at.key] = "";
          }
          console.log(columnLabels);
          let dataForTable;
          if (data) {
            dataForTable = [self.digitizer.getDataById(self.digitizer.getDataObjectsForGeom(toMapLayer.geom_type), data.id).data];
          } else {
            dataForTable = self.digitizer.getLayerDataFromCode(selectedLayerCode);
          }
          for (const cld of dataForTable) {
            let consolidatedData = { ...dataTemplate };

            consolidatedData.id = cld.id;
            for (const atKey in cld.attrs) {
              consolidatedData[atKey] = cld.attrs[atKey];
            }
            let layerGeom = self.digitizer.getVectorLayer(selectedLayerCode).getSource().getFeatureById(cld.id).getGeometry();
            if (self.digitizer.getDataObjectsForGeom(toMapLayer.geom_type) === CONSTANTS.DATA_OBJECTS.POLYGON) {
              let calcArea = layerGeom.getArea();
              calcArea = Math.round((Number(calcArea) / self.options.scaleFactor) * 100) / 100;
              consolidatedData["areaSystem"] = calcArea;
            } else if (self.digitizer.getDataObjectsForGeom(toMapLayer.geom_type) === CONSTANTS.DATA_OBJECTS.LINE) {
              let calcLength = layerGeom.getLength();
              calcLength = Math.round((Number(calcLength) / self.options.scaleFactor) * 100) / 100;
              consolidatedData["lengthSystem"] = calcLength;
            }
            dataforAttributeGrid.push(consolidatedData);
          }

          console.log(dataforAttributeGrid);

          const tableConfig = {
            deleteControls: false,
            footerControls: false,
            zoomControls: true,
            onDataChanged: function (index, field, tableData) {
              console.log(tableData);
              let undoItemGroup = [];
              let selectedLayerCode = self.getSelectedLayerCode();
              let toMapLayer = self.digitizer.getLayerMasterDataFromCode(selectedLayerCode);
              let row = tableData[index];
              let attrs = {};
              attrs[field] = row[field];
              self.digitizer.editAttributes(self.digitizer.getDataObjectsForGeom(toMapLayer.geom_type), row.id, row, undoItemGroup);
            },
            onRowSelected: function (idx) {
              let selectedLayerCode = self.getSelectedLayerCode();

              console.log(dataforAttributeGrid[idx]);
              self.digitizer.focusToGeneratedFeature(selectedLayerCode, dataforAttributeGrid[idx].id);
              // self.doHighlightCustomLayer( dataforAttributeGrid[idx].id, true);
              //self.digitizer.focusToLayerFeature(selectedLayerCode, dataforAttributeGrid[idx].id) ;
            },
            columnConfig: columnLabels,
          };

          const editableTable = new EditableTable(dataforAttributeGrid, tableConfig, "attributeMapping");

          // let attributeGridConfig = {
          //   usageStatistics: false,
          //   el: document.getElementById("attributeMapping"),
          //   data: dataforAttributeGrid,
          //   columns: columnLabels,
          //   rowHeaders: ["checkbox"],
          // };
          // self.attributegrid = new Grid(attributeGridConfig);
          // self.attributegrid.hideColumn("id");
          // self.attributegrid.on("checkAll", (ev) => {
          //   self.doHighlightCustomLayer(undefined, true);
          // });
          // self.attributegrid.on("uncheckAll", (ev) => {
          //   self.doHighlightCustomLayer(undefined, false);
          // });
          // self.attributegrid.on("check", (ev) => {
          //   debugger;
          //   self.doHighlightCustomLayer(self.attributegrid.getRowAt(ev.rowKey).id, true);
          // });
          // self.attributegrid.on("uncheck", (ev) => {
          //   debugger;
          //   self.doHighlightCustomLayer(self.attributegrid.getRowAt(ev.rowKey).id, false);
          // });
        },
      },

      theme: "dark ",
      borderRadius: ".5rem",
      container: "div.bn-container",
      position: "center-top",
      contentSize: {
        width: () => window.innerWidth * 0.8,
        height: "50vh",
      },
      headerControls: { size: "xs" }, // shorthand
      onclosed: function (panel, closedByUser) {
        self.attributePanel = null;
        if (self.attributegrid) {
          self.attributegrid.destroy();
        }
        self.attributegrid = null;
        self.clearSelectedButton();
      },
    });
  }

  // refreshMappingListGrid() {
  //   let genLayerCopy = JSON.parse(JSON.stringify(this.generatedLayers));
  //   let genLayerCodeArray = [];
  //   for (const glc of genLayerCopy) {
  //     genLayerCodeArray.push(glc.layer_code);
  //   }
  //   genLayerCodeArray = [...new Set(genLayerCodeArray)];
  //   let reducedGenLayers = [];
  //   for (const glca of genLayerCodeArray) {
  //     reducedGenLayers.push({
  //       layer_code: glca,
  //       layer_name: Gis.CustomLayerMasterMin["getName"][glca],
  //     });
  //   }
  //   this.mappinglistgrid.resetData(reducedGenLayers);
  //   this.digitizer.clearInteractions();
  // }

  // loadToolBar() {
  //   let self = this;
  //   if (self.options.hide_toolbar === true) {
  //     self.digitizer.loadInitialMap();
  //     if (self.digitizer.options.control_buttons && self.digitizer.options.control_buttons.length > 0) {
  //       for (let i = 0; i < self.digitizer.options.control_buttons.length; i++) {
  //         let btObj = self.options.control_buttons[i];
  //         self.addControlButton(btObj.icon, btObj.text, btObj.style, btObj.on_complete);
  //       }
  //     }
  //     self.constructLayerInSideBar();
  //     //copied here as it was only present in toolbar load
  //     let tayertypes = document.getElementById("tayertypes");
  //     tayertypes.addEventListener("click", function (evt) {
  //       self.layerShow();
  //     });
  //     document.getElementById("closeongenerateddata").addEventListener("click", function (evt) {
  //       self.layerShow();
  //     });
  //     return;
  //   }

  //   // if (self.tollbarPanel && self.tollbarPanel !== null) {
  //   //     try {
  //   //         self.tollbarPanel.close();
  //   //         self.tollbarPanel = null;
  //   //     } catch (err) {

  //   //     }
  //   // }
  //   self.tollbarPanel = jsPanel.create({
  //     headerTitle: "Toolbar",
  //     contentFetch: {
  //       resource: self.servicePathPanel + "_toolbar.html?v=" + self.digitizer.version(),
  //       done: function (response, panel) {
  //         panel.contentRemove();
  //         panel.content.append(jsPanel.strToHtml(response));

  //         self.initTools();
  //         if (self.digitizer.options.action_buttons && self.digitizer.options.action_buttons.length > 0) {
  //           for (let i = 0; i < self.digitizer.options.action_buttons.length; i++) {
  //             self.digitizer.addActionButton(self.options.action_buttons[i]);
  //           }
  //         }

  //         //

  //         self.digitizer.loadInitialMap();
  //         if (self.digitizer.options.control_buttons && self.digitizer.options.control_buttons.length > 0) {
  //           for (let i = 0; i < self.digitizer.options.control_buttons.length; i++) {
  //             let btObj = self.options.control_buttons[i];
  //             self.addControlButton(btObj.icon, btObj.text, btObj.style, btObj.on_complete);
  //           }
  //         }
  //         // self.initLayerMaster();
  //       },
  //     },
  //     position: "right-bottom -5 -50",
  //     contentSize: "132 410",
  //     container: "div.map",
  //     minimizeTo: "parent",
  //     syncMargins: true,
  //     dragit: { snap: true },
  //     // theme: "#181c32 filled",
  //     theme: {
  //       bgContent: "#181c32",
  //       bgFooter: "#000",
  //       bgPanel: "#000000",
  //       colorHeader: "#FFF",
  //     },
  //     // headerControls: 'xs', // shorthand
  //     onclosed: function (panel, closedByUser) {
  //       self.panelSettings = null;
  //     },
  //     // bgFooter:"#181c32",
  //     footerToolbar: "<span>&nbsp;</span>",
  //     headerControls: {
  //       maximize: "remove",
  //       close: "remove",
  //       size: "xs",
  //     },
  //   });
  // }

  showSetings() {
    let self = this;
    if (self.panelSettings && self.panelSettings !== null) {
      try {
        self.panelSettings.close();
        self.panelSettings = null;
      } catch (err) {}
    }
    self.panelSettings = jsPanel.create({
      headerTitle: "Settings",
      contentFetch: {
        resource: template_settings,
        done: function (response, panel) {
          panel.contentRemove();
          panel.content.append(jsPanel.strToHtml(response));

          document.getElementById("_settings_touch_mode").checked = self.digitizer.getTouchMode();
          document.getElementById("_settings_touch_mode").addEventListener("click", function (evt) {
            self.digitizer.setTouchMode(document.getElementById("_settings_touch_mode").checked);
          });
        },
      },
      position: "right-bottom -5 -50",
      contentSize: "250 320",
      borderRadius: ".5rem",
      theme: "dark",
      container: "div.bn-container",
      headerControls: { size: "xs" }, // shorthand
      onclosed: function (panel, closedByUser) {
        self.panelSettings = null;
        self.digitizer.clearTempGeom();
        self.clearSelectedButton();
      },
    });
  }

  exportPDF() {
    let self = this;
    if (self.panelPdf && self.panelPdf !== null) {
      try {
        self.panelPdf.close();
        self.panelPdf = null;
      } catch (err) {}
    }
    self.panelPdf = jsPanel.create({
      headerTitle: "Export PDF",
      contentFetch: {
        resource: template_export_pdf,
        done: function (response, panel) {
          panel.contentRemove();
          panel.content.append(jsPanel.strToHtml(response));

          document.getElementById("do_export_pdf").addEventListener(
            "click",
            function (evt) {
              const dims = {
                a0: [1189, 841],
                a1: [841, 594],
                a2: [594, 420],
                a3: [420, 297],
                a4: [297, 210],
                a5: [210, 148],
              };

              const format = document.getElementById("pdf_paper_size").value;
              const resolution = document.getElementById("pdf_resolution").value;
              const dim = dims[format];
              const width = Math.round((dim[0] * resolution) / 25.4);
              const height = Math.round((dim[1] * resolution) / 25.4);
              const map = self.digitizer.getMap();
              const size = map.getSize();
              const viewResolution = map.getView().getResolution();

              map.once("rendercomplete", function () {
                const mapCanvas = document.createElement("canvas");
                mapCanvas.width = width;
                mapCanvas.height = height;
                const mapContext = mapCanvas.getContext("2d");
                Array.prototype.forEach.call(document.querySelectorAll(".ol-layer canvas"), function (canvas) {
                  if (canvas.width > 0) {
                    const opacity = canvas.parentNode.style.opacity;
                    mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);
                    const transform = canvas.style.transform;
                    // Get the transform parameters from the style's transform matrix
                    const matrix = transform
                      .match(/^matrix\(([^\(]*)\)$/)[1]
                      .split(",")
                      .map(Number);
                    // Apply the transform to the export map context
                    CanvasRenderingContext2D.prototype.setTransform.apply(mapContext, matrix);
                    mapContext.drawImage(canvas, 0, 0);
                  }
                });
                try {
                  mapContext.globalAlpha = 1;
                  mapContext.setTransform(1, 0, 0, 1, 0, 0);
                  const pdf = new jsPDF("landscape", undefined, format);
                  // pdf.text("Hello world!", 1, 1);
                  pdf.addImage(mapCanvas.toDataURL("image/png"), "PNG", 0, 0, dim[0], dim[1]);
                  pdf.save("map.pdf");
                } catch (ex) {
                  console.log(ex);
                  alert("Some layers does not allow exporting.");
                }
                // Reset original map size
                map.setSize(size);
                map.getView().setResolution(viewResolution);
                // exportButton.disabled = false;
                document.body.style.cursor = "auto";
              });

              // Set print size
              const printSize = [width, height];
              map.setSize(printSize);
              const scaling = Math.min(width / size[0], height / size[1]);
              map.getView().setResolution(viewResolution / scaling);
            },
            false
          );
        },
      },
      position: "right-bottom -5 -50",
      contentSize: "250 320",
      borderRadius: ".5rem",
      theme: "dark",
      container: "div.bn-container",
      headerControls: { size: "xs" }, // shorthand
      onclosed: function (panel, closedByUser) {
        self.panelPdf = null;
        self.digitizer.clearTempGeom();
        self.clearSelectedButton();
      },
    });
  }
  // togglemenuBar() {
  //   this.toggleclasses("menubarPane", "dotoggle");
  //   // this.toggleclasses('attributeHamberger', 'content-to-bottom');
  //   this.toggleclasses("tayertypes", "content-to-bottom");
  //   this.digitizer.getMap().updateSize();
  // }

  toggleclasses(elmt, clss) {
    var ele = document.getElementById(elmt);
    var templist = ele.className;
    var classArr = templist.split(/\s+/);
    var eleflag = classArr.includes(clss);
    if (eleflag) {
      ele.classList.remove(clss);
    } else {
      ele.classList.add(clss);
    }
  }

  // initLayerMaster() {
  //   let layerMaster = this.options.layer_master;
  //   let uniqueNames = [];
  //   let samplearray = [];
  //   let lineTabHtml = "";
  //   let xarray = [];
  //   for (var x = 0; x < layerMaster.length; x++) {
  //     let gtype = layerMaster[x].geom_type;
  //     let ltype = layerMaster[x].geom_type; //CONSTANTS.returnLayerForGeom(gtype).split("vector")[1];
  //     uniqueNames.push(ltype);
  //   }
  //   xarray = [...new Set(uniqueNames)]; //not required, need to rethink and remove
  //   let i = 0;
  //   lineTabHtml = '<select name="sample" id="dropdownListType" class="d-inline-block selection addDataFromETSpoints marginleft-16">';

  //   for (i = 0; i < xarray.length; i++) {
  //     lineTabHtml += '<optgroup label="' + xarray[i] + '">';
  //     for (var xc = 0; xc < layerMaster.length; xc++) {
  //       if (layerMaster[xc].hide_from_list && layerMaster[xc].hide_from_list == true) {
  //         continue;
  //       }
  //       let gtype = layerMaster[xc].geom_type;
  //       let ltype = gtype; //CONSTANTS.returnLayerForGeom(gtype).split("vector")[1];
  //       if (xarray[i] === ltype && !samplearray.includes(layerMaster[xc].layer_name)) {
  //         lineTabHtml += '<option name="' + xarray[i] + '" value=' + layerMaster[xc].layer_code + ">" + layerMaster[xc].layer_name + "</option>";
  //         samplearray.push(layerMaster[xc].layer_code);
  //       }
  //     }
  //     lineTabHtml += "</optgroup>";
  //   }
  //   lineTabHtml += "</select>";
  //   var customdiv = document.createElement("span");
  //   customdiv.innerHTML = lineTabHtml;
  //   if (this.options.hide_toolbar === true) {
  //     document.getElementById("datalistdropdown1").append(customdiv);
  //   } else {
  //     document.getElementById("datalistdropdown").append(customdiv);
  //   }
  //   // let selectData = this.options.attributes;
  //   // let contentDiv = document.getElementById('locationinfodistrictClass');
  //   // let blockdiv = document.getElementById('locationinfoblockClass');
  //   // for (let i = 0; i < selectData.length; i++) {
  //   //     if (selectData[i].visible && selectData[i].text) {
  //   //         var temdiv = document.createElement('div');
  //   //         temdiv.className = 'locationDetailscard';
  //   //         var childdiv1 = document.createElement('div');
  //   //         childdiv1.className = 'h6 small clr-hdg';
  //   //         childdiv1.innerHTML = selectData[i].label;

  //   //         var childdiv2 = document.createElement('div');
  //   //         childdiv2.className = 'h6 ';
  //   //         childdiv2.innerHTML = selectData[i].value;

  //   //         temdiv.appendChild(childdiv1);
  //   //         temdiv.appendChild(childdiv2);

  //   //         contentDiv.appendChild(temdiv);
  //   //     }
  //   // }
  //   // for (let i = 0; i < selectData.length; i++) {
  //   //     if (selectData[i].visible && !(selectData[i].text)) {
  //   //         var temdiv = document.createElement('div');
  //   //         temdiv.className = 'blockinfoCard';
  //   //         var childdiv1 = document.createElement('div');
  //   //         childdiv1.className = 'h6 small text-white';
  //   //         childdiv1.innerHTML = selectData[i].label;

  //   //         var childdiv2 = document.createElement('div');
  //   //         childdiv2.className = 'h6 logoText text-white';
  //   //         childdiv2.innerHTML = selectData[i].value;

  //   //         temdiv.appendChild(childdiv1);
  //   //         temdiv.appendChild(childdiv2);

  //   //         blockdiv.appendChild(temdiv);
  //   //     }
  //   // }
  // }

  initTools() {
    var self = this;

    //Clear Interactions .
    this.buildFontButton("__bn-tool-general", "Clear Tools", "fg-arrow-o", function (event) {
      self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.POINTER);
    });

    if (!this.options.visible_tools || CONSTANTS.DEFAULT_VISIBLE_TOOLS.UNDO_REDO in this.options.visible_tools) {
      //Undo button
      this.buildFontButton("__bn-tool-general", "Undo", "fg-help-larrow", function (event) {
        self.digitizer.undoRedoManager.undo();
      });

      //Redo button
      this.buildFontButton("__bn-tool-general", "Redo", "fg-help-rarrow", function (event) {
        self.digitizer.undoRedoManager.redo();
      });
    }

    if (!this.options.visible_tools || CONSTANTS.DEFAULT_VISIBLE_TOOLS.MEASUREMENT in this.options.visible_tools) {
      //Measure Length
      this.buildFontButton("__bn-tool-general", "Measure Length", "fg-measure", function (event) {
        if (!self.digitizer.getTouchMode()) {
          self.digitizer.showAcceptCancelControl(
            function () {
              self.digitizer.interactionManager.finishLineDrawing();
            },
            function () {
              self.digitizer.interactionManager.removeLastPointFromLine();
            }
          );
        }

        self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.DRAW_LINE, { layer_code: "_LINE_MEASUREMENT_" });
      });

      //Measure Area
      this.buildFontButton("__bn-tool-general", "Measure Area", "fg-measure-area-alt", function (event) {
        self.digitizer.showAcceptCancelControl(
          function () {
            self.digitizer.interactionManager.finishPolygonDrawing();
          },
          function () {
            self.digitizer.interactionManager.removeLastPointFromPolygon();
          }
        );

        self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.DRAW_POLYGON, { layer_code: "_AREA_MEASUREMENT_" });
      });
    }
    if (!this.options.visible_tools || CONSTANTS.DEFAULT_VISIBLE_TOOLS.PLACE_POINT in this.options.visible_tools) {
      //Insert Point
      this.buildFontButton("__bn-tool-point", "Insert Point", "fg-insert-point", function (event) {
        self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.PLACE_POINT);
      });
    }

    if (!this.options.visible_tools || CONSTANTS.DEFAULT_VISIBLE_TOOLS.ARC_POINT in this.options.visible_tools) {
      //Arc Point
      this.buildFontButton("__bn-tool-point", "Arc Point", "fg-arc-point", function (event) {
        self.digitizer.interactionManager.removeCurrentInteraction();
        self.arcPointUI();
      });
    }

    if (!this.options.visible_tools || CONSTANTS.DEFAULT_VISIBLE_TOOLS.OFFSET_POINT in this.options.visible_tools) {
      //Offset Point
      this.buildFontButton("__bn-tool-point", "Offset Point", "fg-offset-point", function (event) {
        self.createOffsetPointUI();
      });
    }
    if (!this.options.visible_tools || CONSTANTS.DEFAULT_VISIBLE_TOOLS.CUT_POINT in this.options.visible_tools) {
      //Cut Point
      this.buildFontButton("__bn-tool-point", "Cut Point", "fg-cut-point", function (event) {
        self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.CUT_SEGMENT);
      });

      //Cut Point with Dialog
      this.buildFontButton("__bn-tool-point", "Cut Point with Dialog", "fg-cut-point-dialog", function (event) {
        self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.CUT_SEGMENT_UI);
      });
    }
    if (!this.options.visible_tools || CONSTANTS.DEFAULT_VISIBLE_TOOLS.ANGLE_POINT in this.options.visible_tools) {
      //Angle Point
      this.buildFontButton("__bn-tool-point", "Angle Point", "fg-azimuth", function (event) {
        self.digitizer.interactionManager.removeCurrentInteraction();
        self.createAnglePoint();
      });
    }
    if (!this.options.visible_tools || CONSTANTS.DEFAULT_VISIBLE_TOOLS.MOVE_POINT in this.options.visible_tools) {
      //Move Point
      this.buildFontButton("__bn-tool-point", "Move Point", "fg-move", function (event) {
        self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.EDIT_POINT, { event_source: "MOVE_POINT" });
      });
    }
    if (!this.options.visible_tools || CONSTANTS.DEFAULT_VISIBLE_TOOLS.EDIT_POINT in this.options.visible_tools) {
      //Edit Point
      this.buildFontButton("__bn-tool-point", "Edit Point", "fg-edit-point", function (event) {
        self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.EDIT_POINT);
      });
    }
    if (!this.options.visible_tools || CONSTANTS.DEFAULT_VISIBLE_TOOLS.DELETE_POINT in this.options.visible_tools) {
      //Delete Point
      this.buildFontButton("__bn-tool-point", "Delete Point", "fg-delete-point", function (event) {
        self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.DELETE_POINT);
      });
    }
    if (!this.options.visible_tools || CONSTANTS.DEFAULT_VISIBLE_TOOLS.IMPORT_ETS in this.options.visible_tools) {
      //Import ETS Points
      this.buildFontButton("__bn-tool-point", "Import ETS Points", "fg-ets-import", function (event) {
        self.initetsPanel();
        // try {
        //   self.map.removeInteraction(self.pinPoint);
        //   self.isDrawnEnabled = true;
        // } catch (err) {}
      });
    }
    if (!this.options.visible_tools || CONSTANTS.DEFAULT_VISIBLE_TOOLS.DRAW_LINE in this.options.visible_tools) {
      //Draw Line
      this.buildFontButton("__bn-tool-line", "Draw Line", "fg-draw-line", function (event) {
        if (!self.digitizer.getTouchMode()) {
          self.digitizer.showAcceptCancelControl(
            function () {
              self.digitizer.interactionManager.finishLineDrawing();
            },
            function () {
              self.digitizer.interactionManager.removeLastPointFromLine();
            }
          );
        }
        self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.DRAW_LINE);
      });
    }
    if (!this.options.visible_tools || CONSTANTS.DEFAULT_VISIBLE_TOOLS.DELETE_SEGMENT in this.options.visible_tools) {
      //Delete Segment
      this.buildFontButton("__bn-tool-line", "Delete Line Segment", "fg-delete-segment", function (event) {
        self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.DELETE_SEGMENT);
      });
    }

    if (!this.options.visible_tools || CONSTANTS.DEFAULT_VISIBLE_TOOLS.DELETE_LINE in this.options.visible_tools) {
      //Delete Line
      this.buildFontButton("__bn-tool-line", "Delete Line", "fg-delete-line", function (event) {
        self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.DELETE_LINE);
      });
    }

    if (!this.options.visible_tools || CONSTANTS.DEFAULT_VISIBLE_TOOLS.SNAP_POINT in this.options.visible_tools) {
      //Snap point to line
      this.buildFontButton("__bn-tool-line", "Snap Point To Line", "fg-proj-point", function (event) {
        self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.EDIT_POINT, { event_source: "SNAP_POINT" });
      });
    }
    if (!this.options.visible_tools || CONSTANTS.DEFAULT_VISIBLE_TOOLS.TRIM_LINE in this.options.visible_tools) {
      //Trim Line
      this.buildFontButton("__bn-tool-line", "Trim Line", "fg-trim-line", function (event) {
        self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.SELECT_SEGMENT, { event_source: "TRIM_LINE" });
      });
    }
    if (!this.options.visible_tools || CONSTANTS.DEFAULT_VISIBLE_TOOLS.SELECT_POLYGON in this.options.visible_tools) {
      //Select Polygon
      this.buildFontButton("__bn-tool-polygon", "Select Polygon", "fg-layer-alt", function (event) {
        if (self.digitizer.mergeList && self.digitizer.mergeList.length > 0) {
          //Clear Selection
          self.digitizer.interactionManager.removeCurrentInteraction();
          self.digitizer.clearTempGeom();
          self.digitizer.mergeList = [];
        } else {
          self.digitizer.interactionManager.removeCurrentInteraction();
          self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.SELECT_CURRENT_LAYER_FEATURE, {
            layer_code: self.getSelectedLayerCode(),
            event_source: "MERGE_POLYGONS",
          });
        }
      });
    }

    if (!this.options.visible_tools || CONSTANTS.DEFAULT_VISIBLE_TOOLS.DRAW_POLYGON in this.options.visible_tools) {
      //Draw Polygon
      this.buildFontButton("__bn-tool-polygon", "Draw Polygon", "fg-draw-polygon", function (event) {
        if (!self.digitizer.getTouchMode()) {
          self.digitizer.showAcceptCancelControl(
            function () {
              self.digitizer.interactionManager.finishPolygonDrawing();
            },
            function () {
              self.digitizer.interactionManager.removeLastPointFromPolygon();
            }
          );
        }
        self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.DRAW_POLYGON);
      });
    }
    if (!this.options.visible_tools || CONSTANTS.DEFAULT_VISIBLE_TOOLS.MERGE_POLYGONS in this.options.visible_tools) {
      //Merge Polygon
      this.buildFontButton("__bn-tool-polygon", "Merge Polygons", "fg-merge-polygons", function (event) {
        self.digitizer.mergeSelected();
        self.digitizer.interactionManager.removeCurrentInteraction();
        self.digitizer.clearTempGeom();
      });
    }

    if (!this.options.visible_tools || CONSTANTS.DEFAULT_VISIBLE_TOOLS.SPLIT_POLYGON in this.options.visible_tools) {
      //Live Splitter Line
      this.buildFontButton("__bn-tool-polygon", "Live Splitter Line", "fg-live-splitter-line", function (event) {
        if (!self.digitizer.getTouchMode()) {
          self.digitizer.showAcceptCancelControl(
            function () {
              self.digitizer.interactionManager.finishLineDrawing();
            },
            function () {
              self.digitizer.interactionManager.removeLastPointFromLine();
            }
          );
        }

        self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.DRAW_LINE, { layer_code: "_SPLIT_LINE_", split_mode: "LIVE" });
      });

      //Draw Splitter Line
      this.buildFontButton("__bn-tool-polygon", "Splitter Line", "fg-splitter-line", function (event) {
        if (!self.digitizer.getTouchMode()) {
          self.digitizer.showAcceptCancelControl(
            function () {
              self.digitizer.interactionManager.finishLineDrawing();
            },
            function () {
              self.digitizer.interactionManager.removeLastPointFromLine();
            }
          );

          self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.DRAW_LINE, { layer_code: "_SPLIT_LINE_" });
        }
      });

      //Clear Splitter Line
      this.buildFontButton("__bn-tool-polygon", "Clear Splitter Line", "fg-clear-splitter-line", function (event) {
        self.digitizer.interactionManager.removeCurrentInteraction();
        self.digitizer.clearSplitLines();
        self.digitizer.clearTempGeom();
        self.digitizer.mergeList = [];
      });

      //Split Polygon
      this.buildFontButton("__bn-tool-polygon", "Split Polygons", "fg-split-polygon1", function (event) {
        self.digitizer.interactionManager.removeCurrentInteraction();
        if (self.digitizer.mergeList && self.digitizer.mergeList.length > 0) {
          // self.digitizer.interactionManager.removeCurrentInteraction();
          self.digitizer.splitMultiplePolygons();
          self.digitizer.clearSplitLines();
          self.digitizer.clearTempGeom();
        } else {
          self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.SPLIT_POLYGON);
        }
      });
    }

    if (!this.options.visible_tools || CONSTANTS.DEFAULT_VISIBLE_TOOLS.AREA_DIVISION in this.options.visible_tools) {
      //Area Division
      this.buildFontButton("__bn-tool-polygon", "Area Division", "fg-scale-poly", function (event) {
        if (!self.areDivisionUI) {
          self.areDivisionUI = new AreaDivision(self.digitizer);
        }
        if (!self.areDivisionUI.areaDivisionPanel) {
          self.areDivisionUI.loadUI();
        }
      });
    }

    if (!this.options.visible_tools || CONSTANTS.DEFAULT_VISIBLE_TOOLS.POLYGONIZE_LINES in this.options.visible_tools) {
      //Polygonize
      this.buildFontButton("__bn-tool-polygon", "Generate Polygons from Lines", "fg-polygonize", function (event) {
        let undoItemGroup = [];
        self.digitizer.polyGonizeLine(undoItemGroup);
        self.digitizer.undoRedoManager.pushToUndoStack(undoItemGroup);
      });
    }
    if (!this.options.visible_tools || CONSTANTS.DEFAULT_VISIBLE_TOOLS.DELETE_POLYGON in this.options.visible_tools) {
      //Delete Polygon
      this.buildFontButton("__bn-tool-polygon", "Delete Polygon", "fg-delete-polygon", function (event) {
        self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.DELETE_POLYGON);
      });
    }
    if (!this.options.visible_tools || CONSTANTS.DEFAULT_VISIBLE_TOOLS.GRID in this.options.visible_tools) {
      //Show Grid
      this.buildFontButton("__bn-tool-grid", "Show Grid", "fg-grid1", function (event) {
        if (self.digitizer.interactionManager.getCurrentInteraction() !== CONSTANTS.INTERACTIONS.PLACE_GRID) {
          self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.PLACE_GRID);
        } else {
          self.digitizer.removeGrid();
        }
      });

      //Rotate Grid Left
      this.buildFontButton("__bn-tool-grid", "Rotate Grid Left", "fg-grid-left", function (event) {
        self.digitizer.rotateGridLeft();
      });

      //Rotate Grid Right
      this.buildFontButton("__bn-tool-grid", "Rotate Grid Right", "fg-grid-right", function (event) {
        self.digitizer.rotateGridRight();
      });
    }

    if (!this.options.visible_tools || CONSTANTS.DEFAULT_VISIBLE_TOOLS.VIEW_ATTRIBUTES in this.options.visible_tools) {
      //Edit Attributes
      this.buildFontButton("__bn-tool-properties", "Edit Attributes", "fg-tag", function (event) {
        self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.SELECT_CURRENT_LAYER_FEATURE, {
          // layer_code: self.getSelectedLayerCode(),
          event_source: "EDIT_ATTRIBUTE",
        });
      });
    }
    if (!this.options.visible_tools || CONSTANTS.DEFAULT_VISIBLE_TOOLS.ATTRIBUTES_LIST in this.options.visible_tools) {
      //Edit All Attributes
      this.buildFontButton("__bn-tool-properties", "Attributes List", "fg-tags", function (event) {
        self.constructAndOpenAttributePanel();
      });
    }

    if (!this.options.visible_tools || CONSTANTS.DEFAULT_VISIBLE_TOOLS.BACKGROUND_IMAGE in this.options.visible_tools) {
      //Open Image
      this.buildFontButton("__bn-tool-properties", "Background Image", "fg-world-map-alt", function (event) {
        self.digitizer.imageLayerManager.openMap2Popup();
      });
    }

    if (!this.options.visible_tools || CONSTANTS.DEFAULT_VISIBLE_TOOLS.EXPORT_POINTS in this.options.visible_tools) {
      //Export Points
      this.buildFontButton("__bn-tool-properties", "Export Points", "fg-multipoint", function (event) {
        self.showPointsForExport();
      });
    }
    if (!this.options.visible_tools || CONSTANTS.DEFAULT_VISIBLE_TOOLS.PRINT_TOOLS in this.options.visible_tools) {
      //Print Map
      this.buildFontButton("__bn-tool-properties", "Export map to PDF", "fg-map-print", function (event) {
        self.exportPDF();
      });
    }
    //Settings
    this.buildFontButton("__bn-tool-properties", "Settings", "fg-earth-gear", function (event) {
      self.showSetings();
    });

    if (self.digitizer.options.control_buttons && self.digitizer.options.control_buttons.length > 0) {
      for (let i = 0; i < self.digitizer.options.control_buttons.length; i++) {
        let btObj = self.options.control_buttons[i];
        self.addControlButton(btObj.icon, btObj.text, btObj.style, btObj.on_complete);
      }
    }

    if (self.digitizer.options.action_buttons && self.digitizer.options.action_buttons.length > 0) {
      for (let i = 0; i < self.digitizer.options.action_buttons.length; i++) {
        self.digitizer.addActionButton(self.options.action_buttons[i]);
      }
    }

    ////////////////////////////////////

    // let pointdatabutton = document.getElementById("pointdatabutton");
    // pointdatabutton.addEventListener("click", this.openLayerpanel);
    // // pointdatabutton.append(pointdatabuttonimg);

    // let linedatabutton = document.getElementById("linedatabutton");
    // linedatabutton.addEventListener("click", this.openLayerpanel);
    // // linedatabutton.append(linedatabuttonimg);

    // let polygondatabutton = document.getElementById("polygondatabutton");
    // polygondatabutton.addEventListener("click", this.openLayerpanel);
    // polygondatabutton.append(polygondatabuttonimg);

    // let onscreenGenerateddatabtn = document.getElementById("onscreenGenerateddatabtn");
    // onscreenGenerateddatabtn.addEventListener("click", this.generatedDataShow);
    // onscreenGenerateddatabtn.append(onscreenGenerateddatabtnimg);

    // document.getElementById("closeongenerateddata").addEventListener("click", function (evt) {
    //   self.layerShow();
    // });
    // let saveActionbtn = document.getElementById("saveActionbtn");
    // saveActionbtn.addEventListener("click", function (evt) {
    //     self.digitizer.downloadSelectedLayerAsGeoJson();
    // });

    // let doPlaceLine = document.getElementById("doPlaceLine");
    // doPlaceLine.addEventListener("click", function (evt) {
    //     self.drawbyChoosepoints();
    // });
    // doPlaceLine.append(doPlaceLineimg);

    // clearinteractionbtn.append(clearinteractionbtnimg);

    // importETSdatabtn.append(importETSdatabtnimg);

    // let highligtingBtn = document.getElementById("highligtingBtn");
    // highligtingBtn.addEventListener("click", this.highlightFeature);
    // highligtingBtn.append(highligtingBtnimg);
    // document.getElementById("navbarSwitcher").addEventListener("click", function (evt) {
    //     self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.POINTER);
    //     self.togglemenuBar();
    // });
    //document.getElementById("bulkcleardata").addEventListener("click", this.digitizer.clearSelectedMenu);
    //document.getElementById("bulkdeletedta").addEventListener("click", this.bulkDelete);

    // document.getElementById('clearPolygonSelection').addEventListener("click", function (evt) {

    //     self.digitizer.interactionManager.removeCurrentInteraction();
    //     self.digitizer.clearTempGeom();
    //     self.digitizer.mergeList=[];

    // });

    // let doSplit2 = document.getElementById("doSplit2");
    // doSplit2.addEventListener("click", function (evt) {
    //     // self.digitizer.clearInteractions();
    //     // self.drawLineTool();
    //     // try {
    //     //     self.map.removeInteraction(self.pinPoint);
    //     //     self.isDrawnEnabled = true;
    //     // } catch (err) {

    //     // }

    //     document.getElementById('infoPanel').style.display = "block";
    //     var actions = `  <i id="_poly_split_cancel" class="fg fg-cancel-operation cursorPointer marginleft-16 fg-lg" title="Cancel" ></i>
    //     <i id="_poly_split_select" class="fg fg-layers cursorPointer marginleft-16 fg-lg" title="Select Polygons"></i>
    //     <i id="_poly_split_draw_line" class="fg fg-draw-line cursorPointer marginleft-16 fg-lg" title="Draw Split Line"></i>
    //     <i id="_poly_split_draw_line_accept" class="fg fg-finish-line cursorPointer marginleft-16 fg-lg DN" title="Finish Line"></i>
    //     <i id="_poly_split_draw_line_clear" class="fg fg-discard-line cursorPointer marginleft-16 fg-lg DN" title="Cancel Line"></i>
    //     <i id="_poly_split_clear_line" class="fg fg-clear cursorPointer marginleft-16 fg-lg" title="Clear Operation"></i>
    //     <i id="_poly_split_accept" class="fg fg-accept-operation cursorPointer marginleft-16 fg-lg" title="Split"></i>

    //     `;

    //      document.getElementById('infoPanel').innerHTML = actions;
    //      document.getElementById('_poly_split_cancel').addEventListener("click", function (evt) {

    //         self.digitizer.interactionManager.removeCurrentInteraction();
    //         self.digitizer.clearSplitLines();
    //         self.digitizer.clearTempGeom();
    //         self.digitizer.mergeList=[];
    //         document.getElementById('infoPanel').style.display = "none";

    //     });
    //     document.getElementById('_poly_split_select').addEventListener("click", function (evt) {
    //         document.getElementById('_poly_split_select').classList.add("fg-selection");
    //         document.getElementById('_poly_split_draw_line').classList.remove("fg-selection");
    //         self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.SELECT_CURRENT_LAYER_FEATURE, {layer_code:self.getSelectedLayerCode(),event_source: "MERGE_POLYGONS"});
    //     });
    //     document.getElementById('_poly_split_accept').addEventListener("click", function (evt) {
    //         self.digitizer.interactionManager.removeCurrentInteraction();
    //         self.digitizer.splitMultiplePolygons();
    //         self.digitizer.clearSplitLines();
    //         self.digitizer.clearTempGeom();

    //         document.getElementById('infoPanel').style.display = "none";
    //     });
    //     document.getElementById('_poly_split_draw_line').addEventListener("click", function (evt) {
    //         document.getElementById('_poly_split_select').classList.remove("fg-selection");
    //         document.getElementById('_poly_split_draw_line').classList.add("fg-selection");
    //         self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.DRAW_LINE, {layer_code:'_SPLIT_LINE_'});
    //         document.getElementById("_poly_split_draw_line_accept").classList.remove('DN');
    //         document.getElementById("_poly_split_draw_line_clear").classList.remove('DN');

    //     });

    //     document.getElementById('_poly_split_draw_line_accept').addEventListener("click", function (evt) {

    //         self.digitizer.interactionManager.finishLineDrawing();
    //         document.getElementById("_poly_split_draw_line_accept").classList.add('DN');
    //         document.getElementById("_poly_split_draw_line_clear").classList.add('DN');
    //     });

    //     document.getElementById('_poly_split_draw_line_clear').addEventListener("click", function (evt) {

    //         self.digitizer.interactionManager.removeCurrentInteraction();
    //         document.getElementById("_poly_split_draw_line_accept").classList.add('DN');
    //         document.getElementById("_poly_split_draw_line_clear").classList.add('DN');
    //     });

    //     document.getElementById('_poly_split_clear_line').addEventListener("click", function (evt) {

    //         self.digitizer.interactionManager.removeCurrentInteraction();
    //         self.digitizer.clearSplitLines();
    //         self.digitizer.clearTempGeom();
    //         self.digitizer.mergeList=[];
    //         document.getElementById('_poly_split_select').classList.remove("fg-selection");
    //         document.getElementById('_poly_split_draw_line').classList.remove("fg-selection");

    //     });

    //     document.getElementById('_poly_split_cancel').addEventListener("click", function (evt) {

    //         self.digitizer.interactionManager.removeCurrentInteraction();
    //         self.digitizer.clearSplitLines();
    //         self.digitizer.clearTempGeom();
    //         self.digitizer.mergeList=[];
    //         document.getElementById('infoPanel').style.display = "none";

    //     });
    // });

    // doDrawLinebtn.append(doDrawLinebtnimg);

    // let doUndoBtn = document.getElementById("doUndoBtn");
    // doUndoBtn.addEventListener("click", function (evt) {
    //   self.digitizer.undoRedoManager.undo();
    // });
    // // doUndoBtn.append(doUndoBtnimg);

    // let doRedoBtn = document.getElementById("doRedoBtn");
    // doRedoBtn.addEventListener("click", function (evt) {
    //   self.digitizer.undoRedoManager.redo();
    // });
    // doRedoBtn.append(doRedoBtnimg);
    // document.getElementById("fullscreenSwitcher").addEventListener("click", function (evt) {
    //     self.toggleFullscreen();
    // });

    // doCircleIntersectionPoint.append(doCircleIntersectionPointimg);

    // let doPointonLine = document.getElementById("doPointonLine");
    // doPointonLine.addEventListener("click", function (evt) {
    //     self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.INSERT_POINT_ON_LINE);
    //    // self.linePointUI();
    //     // try {
    //     //     self.map.removeInteraction(self.pinPoint);
    //     //     self.isDrawnEnabled = true;
    //     // } catch (err) {

    //     // }
    // });
    // doPointonLine.append(doPointonLineimg);

    // doDeletePointBtn.append(doDeletePointBtnimg);

    // let extentLineBtn = document.getElementById("doExtentLine");
    // extentLineBtn.addEventListener("click", function (evt) {
    //     //self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.EDIT_POINT,{event_source: "SNAP_POINT"});
    //     self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.SELECT_SEGMENT,{event_source:"EXTENT_LINE"});

    // });

    // doDeleteLineBtn.append(doDeleteLineBtnimg);

    // doPolygonize.append(doPolygonizeimg);

    // doSplit.append(doSplitimg);

    // doRotateGridright.append(doRotateGridrightimg);

    // let doremoveGrid = document.getElementById('doremoveGrid');
    // doremoveGrid.addEventListener("click", function (evt) {
    //     self.digitizer.removeGrid();

    // });
    // doremoveGrid.append(doremoveGridimg);

    // doCutSegment2.append(doCutSegmentimg);

    // let selectData = this.options.attributes;
    // let contentDiv = document.getElementById('locationinfodistrictClass');
    // let blockdiv = document.getElementById('locationinfoblockClass');
    // for (let i = 0; i < selectData.length; i++) {
    //     if (selectData[i].visible && !(selectData[i].editable) && !(selectData[i].text)) {
    //         var temdiv = document.createElement('div');
    //         temdiv.className = 'locationDetailscard';
    //         var childdiv1 = document.createElement('div');
    //         childdiv1.className = 'h6 small clr-hdg';
    //         childdiv1.innerHTML = selectData[i].label;
    //         var childdiv2 = document.createElement('div');
    //         childdiv2.className = 'h6 ';
    //         childdiv2.innerHTML = selectData[i].value;
    //         temdiv.appendChild(childdiv1);
    //         temdiv.appendChild(childdiv2);
    //         contentDiv.appendChild(temdiv);
    //     }
    // }
    // for (let i = 0; i < selectData.length; i++) {
    //     if (selectData[i].visible && selectData[i].text) {
    //         var temdiv = document.createElement('div');
    //         temdiv.className = 'blockinfoCard';
    //         var childdiv1 = document.createElement('div');
    //         childdiv1.className = 'h6 small text-white';
    //         childdiv1.innerHTML = selectData[i].label;

    //         var childdiv2 = document.createElement('div');
    //         childdiv2.className = 'h6 logoText text-white';
    //         childdiv2.innerHTML = selectData[i].value;
    //         temdiv.appendChild(childdiv1);
    //         temdiv.appendChild(childdiv2);
    //         blockdiv.appendChild(temdiv);
    //     }
    // }

    // document.getElementById("open_file").addEventListener("click", function (evt) {
    //   self.digitizer.imageLayerManager.openMap2Popup();
    // });

    // document.getElementById("transparentDivision").addEventListener("click", function (evt) {
    //   try {
    //     document.getElementById("slide-in-data").classList.remove("show");
    //     document.getElementById("transparentDivision").style.display = "none";
    //   } catch (err) {}
    // });
    // /

    //TODO       /// this.preDrawData();  ////

    this.constructLayerInSideBar();
  }

  buildFontButton(groupId, title, font, action) {
    const self = this;
    const button = document.createElement("button");
    button.innerHTML = `<i class="fg ${font} fg-lg fg-white"></i>`;
    button.addEventListener("click", function (event) {
      //  let arrowButton =  document.getElementById("__bn-butoon-arrow");
      //  let i = arrowButton.querySelectorAll("i")[0];
      //  i.classList.remove(self.selectedClass);
      //  i.classList.add(self.defaultClass);
      self.clearSelectedButton();
      self.selectedButton = button;
      let i2 = button.querySelectorAll("i")[0];
      i2.classList.remove(self.defaultClass);
      i2.classList.add(self.selectedClass);
      action(event);
    });
    button.setAttribute("title", title);
    document.getElementById(groupId).appendChild(button);
  }

  addControlButton(icon, text, style, clickFunction) {
    // let template = document.getElementById('action_template');
    const bt = document.createElement("button");
    const img = document.createElement("img");
    bt.appendChild(img);

    // bt.removeAttribute("id");
    if (text) {
      bt.setAttribute("title", text);
    }

    if (icon) {
      if (icon.startsWith("data:")) {
        bt.firstElementChild.setAttribute("src", icon);
      } else {
        bt.firstElementChild.setAttribute("src", this.options.service_path + "assets/" + icon);
      }

      if (style) {
        //style will be allowed only for img element
        bt.firstElementChild.setAttribute("style", style);
      }
    }
    bt.classList.add("icon");

    if (clickFunction) {
      bt.addEventListener("click", function (evt) {
        clickFunction();
      });
    }
    document.getElementById("_extra_control_buttons").append(bt);
  }


  addActionButton(icon, text, style, clickFunction, groupId = "__bn-tool-footer") {


    const bt = document.createElement("button");
    const img = document.createElement("img");
    bt.appendChild(img);



   
    if (text) {
      bt.setAttribute("title", text);
    }
    if (icon) {
      if (icon.startsWith("data:")) {
        bt.firstElementChild.setAttribute("src", icon);
      } else {
        bt.firstElementChild.setAttribute("src", this.options.service_path + "assets/" + icon);
      }

      if (style) {
        //style will be allowed only for img element
        bt.firstElementChild.setAttribute("style", style);
      }
    }

    bt.classList.remove("DN");
    if (clickFunction) {
      bt.addEventListener("click", function (evt) {
        clickFunction();
      });
    }

    document.getElementById(groupId).append(bt);
  }
  clearSelectedButton() {
    if (this.selectedButton) {
      let i = this.selectedButton.querySelectorAll("i")[0];
      i.classList.remove(this.selectedClass);
      i.classList.add(this.defaultClass);
      this.selectedButton = null;
    }
  }

  constructLayerInSideBar() {
    var self = this;
    let opLayers = self.options.output_layers;
    for (const la of opLayers) {
      if (la.base_map) {
        let imgName = osmLogo;
        if (la.map_type === CONSTANTS.REFERENCE_MAP_TYPE.OSM) {
          imgName = osmLogo;
        } else if (la.map_type === CONSTANTS.REFERENCE_MAP_TYPE.BHUVAN) {
          imgName = bhuvanLogo;
        } else if (la.map_type === CONSTANTS.REFERENCE_MAP_TYPE.BING) {
          imgName = bingLogo;
        } else {
          imgName = blankImg;
        }
        let divOuter = document.createElement("div");
        divOuter.classList.add("cardcontent");
        let divInner = document.createElement("div");
        divInner.setAttribute("layerid", la.layer_id);
        divInner.classList.add("cardcontentImage");
        divInner.classList.add("cardContentBack");
        divInner.style = "background:url(" + imgName + ")";
        let inpElm = document.createElement("input");
        inpElm.type = "checkbox";
        inpElm.style = "margin-right: 4px;margin-top: 4px;float: right;";
        if (la.visible) {
          inpElm.setAttribute("checked", true);
        }
        divInner.appendChild(inpElm);
        divInner.addEventListener("click", function (evt) {
          let chkElm = this.getElementsByTagName("input")[0];
          let currentLayerId = this.getAttribute("layerid");
          //for self layer
          if (chkElm.checked) {
            this.classList.remove("border");
            this.classList.remove("border-primary");
            self.digitizer.toggleVisibleInLayer(currentLayerId, false);
          } else {
            this.classList.add("border");
            this.classList.add("border-primary");
            self.digitizer.toggleVisibleInLayer(currentLayerId, true);
          }
          chkElm.checked = !chkElm.checked;
          //for other layers
          let layerUis = this.parentElement.parentElement.childNodes;
          for (let lu of layerUis) {
            lu = lu.childNodes[0];
            let itdLayerId = lu.getAttribute("layerid");
            if (itdLayerId !== currentLayerId) {
              if (chkElm.checked) {
                self.digitizer.toggleVisibleInLayer(itdLayerId, false);
                lu.classList.remove("border");
                lu.classList.remove("border-primary");
                lu.getElementsByTagName("input")[0].checked = false;
              }
            }
          }
        });
        let spanElm = document.createElement("span");
        spanElm.classList.add("cardcontentLabel");
        spanElm.classList.add("h6");
        spanElm.classList.add("small");
        spanElm.innerHTML = la.name;
        divOuter.appendChild(divInner);
        divOuter.appendChild(spanElm);
        document.getElementById("bn-maplist").appendChild(divOuter);
      } else {
        if (!la.searchable) {
          let dest = document.getElementById("overlaytable").getElementsByTagName("tbody")[0];
          let trElm = document.createElement("tr");
          trElm.setAttribute("layerid", la.layer_id);
          trElm.setAttribute("style", "cursor:pointer");
          if (la.source_parms.visible) {
            trElm.innerHTML = "<td width='25px'><input type='checkbox' checked></td><td class='mapiconappend'>" + (la.name ? la.name : la.layer_id) + "</td>";
          } else {
            trElm.innerHTML = "<td width='25px'><input type='checkbox'></td><td class='mapiconappend'>" + (la.name ? la.name : la.layer_id) + "</td>";
          }
          dest.appendChild(trElm);
          trElm.addEventListener("click", function (evt) {
            let chkElm = this.getElementsByTagName("input")[0];
            let currentLayerId = this.getAttribute("layerid");
            //for self layer
            if (chkElm.checked) {
              self.digitizer.toggleVisibleInLayer(currentLayerId, false);
            } else {
              self.digitizer.toggleVisibleInLayer(currentLayerId, true);
            }
            chkElm.checked = !chkElm.checked;
          });
        }
      }
    }
  }
  // toggleFullscreen() {
  //   var getIDV = this.targetDiv;
  //   let getIDVElDIV = document.getElementById(getIDV);
  //   let getIDVEl = document.getElementsByTagName("html")[0]; //making entire view port to full screen
  //   if (getIDVElDIV && getIDVElDIV.hasAttribute("style") && !getIDVElDIV.classList.contains("fullscreened")) {
  //     let rfs = getIDVEl.requestFullscreen || getIDVEl.webkitRequestFullScreen || getIDVEl.mozRequestFullScreen || getIDVEl.msRequestFullscreen;
  //     rfs.call(getIDVEl);
  //   }
  //   this.toggleclasses(getIDV, "fullscreened");
  //   this.digitizer.getMap().updateSize();
  // }
  // toggleViewmode() {
  //   if (!this.options.config.view_only) {
  //     this.options.config.view_only = !this.options.config.view_only;
  //     document.getElementById("controltoolbars").style.display = "none";
  //     document.getElementById("viewOnlymsg").style.display = "block";
  //     // document.getElementById("attributeHamberger").style.display = "none";
  //     if (document.body.contains(this.generatedDataPanel)) {
  //       this.generatedDataPanel.close();
  //       this.generatedDataPanel = null;
  //     }
  //     var sidePanel = document.getElementById("slide-in-data");
  //     var arraypanel = sidePanel.className;
  //     var classArr = arraypanel.split(/\s+/);
  //     var eleflag = classArr.includes("show");
  //     if (eleflag) {
  //       this.toggleclasses("slide-in-data", "show");
  //     }
  //   } else {
  //     this.options.config.view_only = !this.options.config.view_only;
  //     document.getElementById("controltoolbars").style.display = "block";
  //     document.getElementById("viewOnlymsg").style.display = "none";
  //     // document.getElementById("attributeHamberger").style.display = "block";
  //     this.initbasepointPanel();
  //   }
  // }
  // attributeDataShow() {
  //   this.toggleclasses("slide-in-data", "show");
  //   var ele = document.getElementById("slide-in-data");
  //   var tempList = ele.className;
  //   var classArr = tempList.split(/\s+/);
  //   var eleflag = classArr.includes("show");
  // }
  // layerShow() {
  //   this.toggleclasses("slide-in-dataRight", "show");
  //   var ele = document.getElementById("slide-in-dataRight");
  //   var tempList = ele.className;
  //   var classArr = tempList.split(/\s+/);
  //   var eleflag = classArr.includes("show");
  // }
  initetsPanel() {
    var self = this;
    var width = window.innerWidth;
    var height = window.innerHeight;
    var importPanelHeight = height / 2;
    var importPanelWidth = width / 3;
    if (importPanelHeight > 550) {
      importPanelHeight = 550;
    } else {
      importPanelHeight = 430;
    }
    if (importPanelWidth > 400) {
      importPanelWidth = 400;
    } else {
      importPanelWidth = 350;
    }
    if (this.panelPoint && this.panelPoint !== null) {
      this.panelPoint.close();
      this.panelPoint = null;
    }

    let data = [
      {
        name: "",
        x: "",
        y: "",
        z: "",
      },
    ];

    const tableConfig = {
      deleteControls: true,
      zoomControls: false,
      addRowButton: true,
      exportButton: false,
      footerControls: true,
      onRowSelected: function (idx) {},
      columnConfig: {
        name: { visible: true, header: "Name" },
        x: { visible: true, header: "X" },
        y: { visible: true, header: "Y" },
        z: { visible: true, header: "Z" },
      },
    };

    this.panelPoint = jsPanel.create({
      //container: "#"+self.targetDiv,
      headerTitle: "Import Points / ETS Points",
      closeOnEscape: true,
      contentFetch: {
        resource: template_ets_point,
        done: function (response, panel) {
          panel.contentRemove();
          panel.content.append(jsPanel.strToHtml(response));
          //console.log("cccccc " + self.servicePathPanel);

          const editableTable = new EditableTable(data, tableConfig, "_ets_import_table");

          document.getElementById("importsavebtn").addEventListener("click", function (evt) {
            self.addETSImportedPoints(editableTable);
          });
          document.getElementById("_ets_file").addEventListener("change", function (evt) {
            self.importData(editableTable);
          });
        },
      },
      position: "center",
      container: "div.bn-container",
      theme: "dark ",
      contentSize: importPanelWidth + " " + importPanelHeight,
      borderRadius: ".5rem",
      headerControls: { size: "xs" }, // shorthand
      onclosed: function (panel, closedByUser) {
        if (self.panelPoint) {
          // self.panelPoint.close();
          self.panelPoint = null;
          self.clearSelectedButton();
        }
      },
    });
  }
  // initGraticule() {
  //     var self = this;
  //     document.getElementById('map').addEventListener("click", function (e) {
  //         var mouseLoc = document.getElementById('mouse-position').textContent;
  //         var mouseLocList = mouseLoc.split(/, /);
  //         if (!self.isgraticuleshown) {
  //             self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.PLACCE_GRID);
  //             // self.digitizer.createGrid(Number(mouseLocList[0]), Number(mouseLocList[1]), self.gridSpaceing);
  //             // self.isgraticuleshown=true;
  //         }
  //     });
  // }

  openLayerpanel(evt) {
    var getID;
    if (!document.getElementById(evt)) {
      getID = evt.currentTarget.id;
    } else {
      getID = document.getElementById(evt).id;
    }

    if (getID === "pointdatabutton") {
      document.getElementById("grid").style.display = "block";
      document.getElementById("linesData").style.display = "none";
      document.getElementById("PolygonData").style.display = "none";
    } else if (getID === "linedatabutton") {
      document.getElementById("grid").style.display = "none";
      document.getElementById("linesData").style.display = "block";
      document.getElementById("PolygonData").style.display = "none";
    } else {
      document.getElementById("grid").style.display = "none";
      document.getElementById("linesData").style.display = "none";
      document.getElementById("PolygonData").style.display = "block";
    }
    var i, tabcontent, tablinks;
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    evt.currentTarget.className += " active";
  }

  initbasepointPanel() {
    var self = this;
    let vPh = window.innerHeight - 400;
    jsPanel.ziBase = 101;
    self.generatedDataPanel = jsPanel.create({
      headerTitle: "Imported ETS Points / Base Points",
      contentFetch: {
        resource: self.servicePathPanel + "_generateddata.html?v=" + self.digitizer.version(),
        done: function (response, panel) {
          panel.contentRemove();
          panel.content.append(jsPanel.strToHtml(response));
          document.getElementById("rawptallchkbox").addEventListener("change", function () {
            self.doHighlight(undefined, "raw");
          });
          document.getElementById("rawptDeleteBtn").addEventListener("click", function () {
            self.bulkDelete("rawpoints");
          });
          //self.refreshetsPoints();
        },
      },
      position: "right-top -40 120",
      contentSize: "460 " + vPh,
      borderRadius: ".5rem",
      container: "div.bn-container",
      theme: "dark",
      headerControls: {
        maximize: "remove",
        close: "remove",
        size: "xs",
      },
    });
  }

  showcustomAlert(msg, type) {
    let self = this;
    document.getElementById("infoPanel").style.display = "block";
    var txt;
    if (type === CONSTANTS.ALERTS.SUCCESS) {
      txt =
        '<img class="cursorPointer marginleft-5 copyToClipboard" title="Copy to Clipboard" alt="" width="15" src=' +
        self.options.service_path +
        "assets/img/tickSuccess.svg>";
    } else if (type === CONSTANTS.ALERTS.ERROR) {
      txt =
        '<img class="cursorPointer marginleft-5 copyToClipboard" title="Copy to Clipboard" alt="" width="15" src=' +
        self.options.service_path +
        "assets/img/tickError.svg>";
    } else {
      txt =
        '<img class="cursorPointer marginleft-5 copyToClipboard" title="Copy to Clipboard" alt="" width="15" src=' +
        self.options.service_path +
        "assets/img/tickWarning.svg>";
    }
    document.getElementById("infoPanel").innerHTML = msg + txt;
    setTimeout(function () {
      document.getElementById("infoPanel").innerHTML = "";
      document.getElementById("infoPanel").style.display = "none";
    }, 2000);
  }

  ///////////////////////////////

  // refreshPointsInDataDisplay() {
  //   var self = this;
  //   this.grid.resetData(this.gridData.segment);
  //   this.grid.restore(this.gridData.segment);
  //   var seg = document.getElementById("grid").querySelectorAll(".datasegmentClass");
  //   seg.forEach(function (data, i) {
  //     data.addEventListener("click", function (evt) {
  //       var id = data.getAttribute("references");
  //       self.predeleteSegment(id);
  //     });
  //   });
  // }
  getEditableAttributes(attr) {
    var data = [];
    for (var i = 0; i < attr.length; i++) {
      if (attr[i].visible && attr[i].editable) {
        data.push(attr[i]);
      }
    }
    return data;
  }
  getDataPopulation(polygons, field, i) {
    if (!i) {
      var subData = [];
      for (var a = 0; a < polygons.length; a++) {
        subData.push(polygons[a][field]);
      }
      var uniq = [...new Set(subData)];
      return uniq;
    } else {
    }
  }
  constructAttributeOptions() {
    var finalAttr = {};
    var polyid = "";
    if (this.initialData.output.prefilled_data.polygons && this.initialData.output.prefilled_data.polygons.length > 0) {
      var attr = this.getEditableAttributes(this.initialData.attributes);
      for (var j = 0; j < attr.length; j++) {
        var ele = document.createElement("select");
        ele.id = polyid;
        ele.references = polyid;
        ele.className = "polygonDataclass";
        var tabContent = "";
        var subData = this.getDataPopulation(this.initialData.output.prefilled_data.polygons, attr[j].field);
        for (var g = 0; g < subData.length; g++) {
          tabContent += '<option value="' + subData[g] + '" >' + subData[g] + "</option>";
        }
        ele.innerHTML = tabContent;
        finalAttr[attr[j].field] = ele;
      }
    } else {
      var attr = this.getEditableAttributes(this.initialData.attributes);
      var outputAttr = this.initialData.output.attributes;
      for (var i = 0; i < attr.length; i++) {
        var ele = document.createElement("select");
        ele.id = polyid;
        ele.references = polyid;
        ele.className = "polygonDataclass";
        var optnsHtml = "";
        for (var j = 0; j < outputAttr.length; j++) {
          optnsHtml += '<option value="' + outputAttr[j][attr[i].field] + '">' + outputAttr[j][attr[i].field] + "</option>";
        }
        ele.innerHTML = optnsHtml;
        finalAttr[attr[i].field] = ele;
      }
    }
    return finalAttr;
  }
  // refreshPolygonsInDataDisplay(isInit) {
  //     var self = this;
  //     var griddivpoly = document.getElementById('PolygonData');
  //     griddivpoly.innerHTML = "";
  //     var tabContentHead = '<table class="table table-head-fixed text-nowrap"> <thead><tr>';
  //     tabContentHead += '<th class="rowwidth"><input type="checkbox" id="allparcelchkbox"> </th><th>Name</th><th>Area</th><th>Action</th>';
  //     var attr = this.getEditableAttributes(this.initialData.attributes);
  //     var polygons = this.generatedData.polygons;
  //     var constructedOPts = this.constructAttributeOptions();
  //     for (var i = 0; i < attr.length; i++) {
  //         tabContentHead += '<th>' + attr[i].label + '</th>';
  //     }
  //     tabContentHead += '</tr></thead><tbody id="parceldataTab"></tbody></table>';

  //     griddivpoly.innerHTML = tabContentHead;
  //     if (this.initialData.config.attributes_display === "combo") {
  //         for (var i = 0; i < polygons.length; i++) {
  //             var tabContent = '';
  //             tabContent += '<td><input type="checkbox" class="parceldatacheckbox" id="' + polygons[i].id + '" curresponds="' + polygons[i].id + '"></td>';
  //             tabContent += '<td>' + polygons[i].name + '</td>';
  //             tabContent += '<td>' + polygons[i].area + '</td>';
  //             tabContent += '<td><img class="polygonDeletebtn cursorPointer" width="15" src=' + Gis.ServicePath + 'assets/img/trash.svg references="' + polygons[i].id + '" ></td>';

  //             for (var j = 0; j < attr.length; j++) {
  //                 var dom = constructedOPts[attr[j].field];
  //                 dom.setAttribute("id", polygons[i].id + attr[j].field);
  //                 dom.setAttribute("references", polygons[i].id);
  //                 if (isInit && this.initialData.output.prefilled_data.polygons && this.initialData.output.prefilled_data.polygons.length > 0 && this.initialData.output.prefilled_data.polygons[i][attr[j].field]) {//prefill data is available
  //                     for (var q = 0; q < dom.options.length; q++) {
  //                         if (dom.options[q].innerText === polygons[i][attr[j].field]) {
  //                             dom.options[q].setAttribute("selected", "selected");
  //                         }
  //                     }
  //                 }

  //                 tabContent += '<td>' + dom.outerHTML + '</td>';
  //             }
  //             var tabRow = document.createElement('tr');
  //             tabRow.innerHTML = tabContent;
  //             document.getElementById('parceldataTab').appendChild(tabRow);
  //         }
  //     } else if (this.initialData.config.attributes_display === "text") {
  //         for (var i = 0; i < polygons.length; i++) {
  //             var tabContent = '';
  //             tabContent += '<td><input type="checkbox" class="parceldatacheckbox" id="' + polygons[i].id + '" curresponds="' + polygons[i].id + '"></td>';
  //             tabContent += '<td>' + polygons[i].name + '</td>';
  //             tabContent += '<td>' + polygons[i].area + '</td>';
  //             tabContent += '<td><img class="polygonDeletebtn cursorPointer" width="15" src=' + Gis.ServicePath + 'assets/img/trash.svg references="' + polygons[i].id + '" ></td>';
  //             for (var j = 0; j < attr.length; j++) {
  //                 var name = "";
  //                 name = polygons[i][attr[j].field];
  //                 tabContent += '<td> <input class="polygonDataclass" type="text" value="' + name + '" references="' + polygons[i].id + '" id="' + polygons[i].id + attr[j].field + '" ></td>';
  //             }
  //             var tabRow = document.createElement('tr');
  //             tabRow.innerHTML = tabContent;
  //             document.getElementById('parceldataTab').appendChild(tabRow);
  //         }
  //     }
  //     var parcelData = document.getElementById('PolygonData');
  //     parcelData.querySelectorAll('.parceldatacheckbox').forEach(function (data, i) {
  //         data.addEventListener("change", function (evt) {
  //             var id = data.getAttribute("id");
  //             self.doHighlight(id, 'polygons', data.checked);
  //         });
  //     });
  //     parcelData.querySelectorAll('.polygonDataclass').forEach(function (data, i) {
  //         data.addEventListener("change", function (evt) {
  //             var id = data.getAttribute('references');
  //             var attr = self.initialData.attributes;
  //             var feat = self.GetFeature(Gis.LAYERTYPE.POLYGON, Gis.LAYERGETTYPE.ID, id);
  //             var oldname;
  //             for (var y = 0; y < self.generatedData.polygons.length; y++) {
  //                 if (self.generatedData.polygons[y].id === id) {
  //                     oldname = self.generatedData.polygons[y].name;
  //                 }
  //             }
  //             oldname += "( ";
  //             var first = 0;
  //             for (var t = 0; t < attr.length; t++) {
  //                 if (attr[t].visible && attr[t].editable) {
  //                     var getData = document.getElementById(id + attr[t].field).value;
  //                     for (var y = 0; y < self.generatedData.polygons.length; y++) {
  //                         if (self.generatedData.polygons[y].id === id) {
  //                             self.generatedData.polygons[y][attr[t].field] = getData;
  //                         }
  //                     }
  //                     if (first === 0) {
  //                         oldname += getData;
  //                         first = first + 1;
  //                     } else {
  //                         oldname += " / " + getData;
  //                     }
  //                 }
  //             }
  //             oldname += " )";
  //             feat[0].set("name", oldname);
  //         });
  //     });
  //     document.getElementById('allparcelchkbox').addEventListener("change", function (evt) {
  //         self.doHighlight(undefined, 'polygons', document.getElementById('allparcelchkbox').checked);
  //     });
  //     parcelData.querySelectorAll('.polygonDeletebtn').forEach(function (data, i) {
  //         data.addEventListener("click", function (evt) {
  //             var id = data.getAttribute("references");
  //             self.predeletePolygon(id, false, false, true);
  //         });
  //     });

  // }
  // refreshLinesInDataDisplay() {
  //     var self = this;
  //     this.linegrid.resetData(this.gridData.lines);
  //     this.linegrid.restore(this.gridData.lines);
  //     var lns = document.getElementById('linesData').querySelectorAll('.datalineClass');
  //     lns.forEach(function (data, i) {
  //         data.addEventListener("click", function (evt) {
  //             var id = data.getAttribute("references");
  //             self.preDeleteLine(id);
  //         });
  //     });
  // }
  openLayerpanel(evt) {
    var cityName = "";
    var getID;
    if (!document.getElementById(evt)) {
      getID = evt.currentTarget.id;
    } else {
      getID = document.getElementById(evt).id;
    }

    if (getID === "pointdatabutton") {
      document.getElementById("grid").style.display = "block";
      document.getElementById("linesData").style.display = "none";
      document.getElementById("PolygonData").style.display = "none";
    } else if (getID === "linedatabutton") {
      document.getElementById("grid").style.display = "none";
      document.getElementById("linesData").style.display = "block";
      document.getElementById("PolygonData").style.display = "none";
    } else {
      document.getElementById("grid").style.display = "none";
      document.getElementById("linesData").style.display = "none";
      document.getElementById("PolygonData").style.display = "block";
    }
    var i, tabcontent, tablinks;
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    evt.currentTarget.className += " active";
  }

  getSelectedLayerCode() {
    return this.digitizer.layerChooser.getSelected();

    // let selectBox = document.getElementById("dropdownListType");
    // let index = document.getElementById("dropdownListType").selectedIndex;
    // let selectedLayerCode = selectBox.options[index].value;
    // return selectedLayerCode;
  }

  generatefromData() {
    this.clearInteractions();
    this.isDrawnEnabled = true;
    let index = document.getElementById("dropdownListType").selectedIndex;
    let selectBox = document.getElementById("dropdownListType");
    let selectedIndexName = selectBox.options[index].getAttribute("name");
    if (selectedIndexName === "Points" || selectedIndexName === "Lines" || selectedIndexName === "Polygon") {
      this.initpickPanel(selectedIndexName === "polygons");
    } else {
      alert("Caught an error or mismatch type");
    }
  }

  clearSelectedMenu() {
    this.grid.resetData(this.generatedData.points);
    this.linegrid.resetData(this.generatedData.lines);
    this.polygrid.resetData(this.generatedData.polygons);
    this.clearInteractions();
  }

  // toggleInputFormat(elem) {
  //   var self = this;
  //   var tabHtml =
  //     "<table class='table' id='inppttab'><tr><td ><input value='' type='text' class='form-control fullWidth' name='namer1' placeholder='Name'></td><td ><input value='' type='text' class='form-control fullWidth' name='p1r1' placeholder='X'>";
  //   tabHtml +=
  //    `</td><td><input type='text' class='form-control fullWidth' value='' name='p2r1' placeholder='Y'></td>
  //    <td><input type='text' class='form-control fullWidth' value='' name='p3r1' placeholder='Z'></td>
  //     <td id='removerowBtn' onclick=''><img alt='' width='15' title='Delete' class='cursorPointer margintop-6' src='assets/img/trash.svg'></td></tr><tr><td colspan='3'  id='addrow'></td></tr>`;
  //   tabHtml += "</table>";
  //   //console.log('Entered to toggle');
  //   let inpFormat;
  //   if (elem === undefined) {
  //     if (document.getElementById("datatypeswitch")) {
  //       inpFormat = document.getElementById("datatypeswitch").checked;
  //     } else {
  //       inpFormat = false;
  //     }
  //   } else {
  //     inpFormat = document.getElementById("datatypeswitch").checked;
  //   }
  //   if (document.getElementById("addRowbtn")) {
  //     if (inpFormat) {
  //       document.getElementById("addRowbtn").style.display = "none";
  //     } else {
  //       document.getElementById("addRowbtn").style.display = "inline-block";
  //     }
  //   }
  //   //document.getElementById('inppttab').value.length
  //   if (!inpFormat) {
  //     this.isControlInCSV = false;
  //     if (!document.getElementById("inppttab")) {
  //       if (document.getElementById("tab")) {
  //         document.getElementById("tab").innerHTML = tabHtml;
  //       }
  //     }
  //     if (document.getElementById("tab")) {
  //       document.getElementById("tab").classList.remove("DN");
  //     }
  //     if (document.getElementById("area")) {
  //       document.getElementById("area").classList.add("DN");
  //     }
  //   } else {
  //     this.isControlInCSV = true;
  //     if (!document.getElementById("inpptarea")) {
  //       var inpAreaHtml =
  //         '<h6>Upload a CSV formatted file or Directly enter the input.</h6><div class="flexDisplay fullWidth"><input type="file" class="form-control" id="fileUpload" data-browse-on-zone-click="true"/></div><br>';
  //       inpAreaHtml += '<div><textarea id="inpptarea" placeholder="name1,pt1,pt2,pt3&#13;&#10;name2,pt1,pt2,pt3"></textarea></div>';
  //       document.getElementById("area").innerHTML = inpAreaHtml;
  //     }
  //     document.getElementById("area").classList.remove("DN");
  //     document.getElementById("tab").classList.add("DN");
  //     document.getElementById("fileUpload").addEventListener("change", function (evt) {
  //       self.importData();
  //     });
  //   }
  // }
  importData(table) {
    var fileUpload = document.getElementById("_ets_file");
    var regex = /^([a-zA-Z0-9\s_\\.\-:])+(.csv|.txt)$/;
    if (regex.test(fileUpload.value.toLowerCase())) {
      if (typeof FileReader !== "undefined") {
        var reader = new FileReader();
        reader.onload = function (e) {
          const content = e.target.result;
          const lines = content.split("\n");

          const data = [];

          for (let i = 0; i < lines.length; i++) {
            const line = lines[i].split(",");
            if (line.length >= 3) {
              const obj = { name: line[0], x: line[1], y: line[2], z: line.length > 3 ? line[3] : 0 };
              data.push(obj);
            }
          }
          table.initialData.splice(0, table.initialData);
          table.initialData = data;
          table.populateTable();
        };
        reader.readAsText(fileUpload.files[0]);
      } else {
        alert("This browser does not support HTML5.");
      }
    } else {
      alert("Please upload a valid CSV file.");
    }
  }

  addETSImportedPoints(table) {
    var datapresent = false;
    let pointsData = [];

    if (table.initialData.length > 0) {
      for (var i = 0; i < table.initialData.length; i++) {
        if (!isNaN(table.initialData[i].x) && !isNaN(table.initialData[i].y)) {
          let wktPt = `POINT(${table.initialData[i].x} ${table.initialData[i].y})`; //this._wktFormat.writeGeometry(pt);
          if (!isNaN(table.initialData[i].z)) {
            wktPt = `POINT Z(${table.initialData[i].x} ${table.initialData[i].y} ${table.initialData[i].z})`;
          }

          let pointData = {
            id: this.digitizer.createID(),
            name: table.initialData[i].name,
            geom: wktPt,
          };
          pointsData.push(pointData);
        }
      }
      if (pointsData.length > 0) {
        this.digitizer.addPointObjectArray(pointsData, []);
      }
    } else {
      alert("Please choose any CSV file");
    }
  }

  arcPointUI() {
    var self = this;
    console.log(template_arc_point);
    if (self.panelArcPoint) {
      self.panelArcPoint.close((id) => {
        self.panelArcPoint = null;
      });
    }
    self.panelArcPoint = jsPanel.create({
      headerTitle: "Arc Point",
      contentFetch: {
        resource: template_arc_point,
        done: function (response, panel) {
          panel.contentRemove();
          console.log(response);
          panel.content.append(jsPanel.strToHtml(response));
          document.getElementById("arcPanelClose").addEventListener("click", function (evt) {
            self.panelArcPoint.close();
            self.panelArcPoint = null;
          });

          var ptCombo = document.getElementById("arcPointPanel").querySelectorAll(".point-combo");
          ptCombo.forEach(function (data, i) {
            for (var i = 0; i < self.digitizer.generatedData.points.length; i++) {
              let nm = self.digitizer.generatedData.points[i]["name"];
              let idx = i;
              var pts = document.createElement("option");
              pts.value = idx;
              pts.innerHTML = nm;
              data.appendChild(pts);
            }
          });

          var acrPt = document.getElementById("arcPointPanel").querySelectorAll(".arcpoint_input");
          acrPt.forEach(function (data, i) {
            data.addEventListener("change", function (evt) {
              self.digitizer.showArcPoint("arcPointPanel", data.id);
            });
          });

          // $(".arcpoint_input").each(function () {
          //     $(this).change(function () {

          //         self.showArcPoint(this.id);
          //     });
          // });
          document.getElementById("add_arc_point").addEventListener("click", function () {
            let idx1 = document.getElementById("combo_first").value;
            let idx2 = document.getElementById("combo_second").value;
            if (idx1 !== idx2) {
              self.digitizer.addPointsFromTemp("A");
            }
            self.panelArcPoint.close();
            self.panelArcPoint = null;
          });
        },
      },
      theme: "dark",
      borderRadius: ".5rem",
      contentSize: "200 410",
      // resizeit: true,
      container: "div.bn-container",
      position: "left-bottom 5 -50",
      // footerToolbar: "<span>&nbsp;</span>",
      headerControls: { size: "xs" }, // shorthand
      onclosed: function (panel, closedByUser) {
        self.digitizer.clearTempGeom();
        self.clearSelectedButton();
        self.panelArcPoint = null;
      },
    });
  }

  constructAndOpenEditPointPanel(dataArg) {
    var self = this;
    if (self.editPointPanel) {
      //not required
    } else {
      self.editPointPanel = jsPanel.create({
        closeOnEscape: true,
        headerTitle: "Edit Point",
        footerToolbar: '<button id="doneEditing" class="btn btn-primary cardAction-btn">OK</button>',
        contentFetch: {
          resource: template_edit_point,
          done: function (response, panel) {
            panel.contentRemove();
            panel.content.append(jsPanel.strToHtml(response));

            //on change of textbox
            document.getElementById("editingCanvasRow").addEventListener("change", function (evt) {
              if (evt.target.name === "editPointName") {
                let elm = document.getElementById("editPointIntersectionPanel");
                if (!elm.classList.contains("DN")) {
                  elm.classList.add("DN");
                }
                let x = document.getElementById("editPointX");
                let y = document.getElementById("editPointY");
                let z = document.getElementById("editPointZ");
                let coord = [Number(x.value), Number(y.value)];
                let oldCoord = [Number(x.value), Number(y.value)];

                if (self.digitizer.options.dimensions == 3) {
                  coord.push(Number(z.value));
                  oldCoord.push(Number(z.value));
                }
                let id = document.getElementById("editingCanvasRow").getAttribute("editPoint_id");
                let retObj = {
                  id: id,
                  coord: coord,
                  old_coord: oldCoord,
                };
                self.digitizer.changePointCoordinateTemp(retObj);
              }
            });

            document.getElementById("editPointFreehand").addEventListener("click", function (evt) {
              let elm = document.getElementById("editPointIntersectionPanel");
              if (!elm.classList.contains("DN")) {
                elm.classList.add("DN");
              }
              let x = document.getElementById("editPointX");
              let y = document.getElementById("editPointY");
              let z = document.getElementById("editPointZ");

              self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.EDIT_POINT_FREEHAND, {
                x: Number(x.value),
                y: Number(y.value),
                z: self.digitizer.options.dimensions == 3 ? Number(z.value) : 0,
                onModify: function (newX, newY, newZ) {
                  let xEl = document.getElementById("editPointX");
                  let yEl = document.getElementById("editPointY");
                  let zEl = document.getElementById("editPointZ");
                  xEl.value = newX;
                  yEl.value = newY;
                  zEl.value = newZ;

                  let oldCoord1 = [Number(x.getAttribute("old")), Number(y.getAttribute("old"))];
                  let newCoord1 = [newX, newY];
                  if (self.digitizer.options.dimensions == 3) {
                    oldCoord1.push(Number(z.getAttribute("old")));
                    newCoord1.push(newZ);
                  }
                  //   let retObj = { id: "idd", coord: [newX, newY], old_coord: [Number(x.getAttribute("old")), Number(y.getAttribute("old"))] };
                  self.digitizer.changePointCoordinateTemp(oldCoord1, newCoord1);
                },
              });
            });
            document.getElementById("editPointIntersection").addEventListener("click", function (evt) {
              let elm = document.getElementById("editPointIntersectionPanel");
              if (elm.classList.contains("DN")) {
                elm.classList.remove("DN");
              }

              ///////////////////////////////
              // let id = document.getElementById("editingCanvasRow").getAttribute("editPoint_id");
              // let ftClone = self.getFeatureByGeomType(Gis.POINTTYPE, Gis.LAYERGETTYPE.ID, id)[0];
              // let ftOriginal = ftClone.clone();

              //  self.tempLayer.getSource().addFeature(ftClone);

              var ptCombo = document.getElementById("editPointIntersectionPanel").querySelectorAll(".point-combo");
              ptCombo.forEach(function (data, i) {
                for (var i = 0; i < self.digitizer.generatedData.points.length; i++) {
                  let nm = self.digitizer.generatedData.points[i]["name"];
                  let idx = i;
                  var pts = document.createElement("option");
                  pts.value = idx;
                  pts.innerHTML = nm;
                  data.appendChild(pts);
                }
              });

              var acrPt = document.getElementById("editPointIntersectionPanel").querySelectorAll(".arcpoint_input");
              acrPt.forEach(function (data, i) {
                data.addEventListener("change", function (evt) {
                  self.digitizer.showArcPoint("editPointPanel", data.id, function (coordinate) {
                    let x = document.getElementById("editPointX");
                    let y = document.getElementById("editPointY");
                    let z = document.getElementById("editPointZ");
                    // let retObj = { id: ftOriginal.get("id"), coord: coordinate, old_coord: ftOriginal.getGeometry().getCoordinates() };
                    let oldCoord2 = [Number(x.getAttribute("old")), Number(y.getAttribute("old"))];
                    if (self.digitizer.options.dimensions == 3) {
                      oldCoord2.push(Number(z.getAttribute("old")));
                    }
                    self.digitizer.changePointCoordinateTemp(oldCoord2, coordinate);
                    x.value = coordinate[0];
                    y.value = coordinate[1];
                    if (self.digitizer.options.dimensions == 3) {
                      z.value = coordinate[2];
                    }
                  });
                });
              });

              // $(".arcpoint_input").each(function () {
              //     $(this).change(function () {

              //         self.showArcPoint(this.id);
              //     });
              // });
              // document.getElementById('editPointIntersectionPanel').querySelector('.add_arc_point').addEventListener("click", function () {

              //     let idx1 =  document.getElementById('editPointIntersectionPanel').querySelectorAll('point-combo')[0].value;
              //     let idx2 =  document.getElementById('editPointIntersectionPanel').querySelectorAll('point-combo')[1].value;
              //     if (idx1 !== idx2) {
              //         let features = self.tempLayer.getSource().getFeatures();
              //         for (var i = 0; i < features.length; i++) {
              //             if (features[i].get("category") === "A") {

              //                 let ptName = features[i].get("name");
              //                 let wktPt = self._wktFormat.writeGeometry(features[i].getGeometry());
              //                 let pointData = { "name": ptName, "geom": wktPt, "type": Gis.POINTTYPE.CREATED, "idx": i };
              //                 self.preCreatePoint(pointData);
              //                 self.clearTempGeom();
              //                 break;
              //             }
              //         }
              //     }
              //     self.panelArcPoint.close();
              //     self.panelArcPoint = null;
              // });
              ////////////////////////////////

              // let x = document.getElementById("editPointX");
              // let y = document.getElementById("editPointY");
              // let id = document.getElementById("editingCanvasRow").getAttribute("editPoint_id");
              // let retObj = { id: id, coord: [Number(x.value), Number(y.value)], old_coord: [Number(x.getAttribute("old")), Number(y.getAttribute("old"))] };
              // self.changePointCoordinateTemp(retObj);
            });

            //ok button action
            document.getElementById("doneEditing").addEventListener("click", function (evt) {
              let x = document.getElementById("editPointX");
              let y = document.getElementById("editPointY");
              let z = document.getElementById("editPointZ");
              let id = document.getElementById("editingCanvasRow").getAttribute("editPoint_id");
              let undoItemGroup = [];
              let oldCoord3 = [Number(x.getAttribute("old")), Number(y.getAttribute("old"))];
              let newCoord3 = [Number(x.value), Number(y.value)];
              if (self.digitizer.options.dimensions == 3) {
                oldCoord3.push(Number(z.getAttribute("old")));
                newCoord3.push(Number(z.value));
              }
              self.digitizer.changePointCoordinate(oldCoord3, newCoord3, undoItemGroup);
              self.digitizer.undoRedoManager.pushToUndoStack(undoItemGroup);
              self.editPointPanel.close();
            });

            let data = self.digitizer.getDataById("points", dataArg.id);

            document.getElementById("editPointName").value = data.data.name;
            let x = document.getElementById("editPointX");
            x.setAttribute("old", dataArg.coordinates[0]);
            x.value = dataArg.coordinates[0];
            let y = document.getElementById("editPointY");
            y.value = dataArg.coordinates[1];
            y.setAttribute("old", dataArg.coordinates[1]);

            let z = document.getElementById("editPointZ");
            z.value = self.digitizer.options.dimensions == 3 ? dataArg.coordinates[2] : 0;
            z.setAttribute("old", z.value);
            document.getElementById("editingCanvasRow").setAttribute("editPoint_id", dataArg.id);
          },
        },

        theme: "dark ",
        container: "div.bn-container",
        borderRadius: ".5rem",
        position: "center-top",
        contentSize: {
          width: () => window.innerWidth * 0.4,
          height: "50vh",
        },
        headerControls: { size: "xs" }, // shorthand
        onclosed: function (panel, closedByUser) {
          self.digitizer.interactionManager.removeCurrentInteraction();
          self.digitizer.clearTempGeom();
          self.editPointPanel = null;
          this.editPointPanel = null;
          self.clearSelectedButton();
        },
      });
    }
  }

  lineOnPointUI(featureId, coordinates) {
    var self = this;
    if (self.panelPointOnLine && self.panelPointOnLine !== null) {
      self.panelPointOnLine.close((id) => {
        self.panelPointOnLine = null;
      });
    }
    let pointsData = self.digitizer.generatePointsData(coordinates);
    document.getElementById("infoPanel").style.display = "block";
    document.getElementById("infoPanel").innerHTML = "Select a line";
    // var selection = await self.pickDataFromMap(Gis.LAYERTYPE.LINES);
    // if (selection) {
    document.getElementById("infoPanel").innerHTML = "";
    document.getElementById("infoPanel").style.display = "none";
    self.panelPointOnLine = jsPanel.create({
      headerTitle: "Insert Point",
      contentFetch: {
        resource: self.servicePathPanel + "_pointonline.html?v=" + self.digitizer.version(),
        done: function (response, panel) {
          panel.contentRemove();
          panel.content.append(jsPanel.strToHtml(response));

          // for (var ty = 0; ty < self.generatedData.lines.length; ty++) {
          //     var polyOption = document.createElement('option');
          //     polyOption.value = self.generatedData.lines[ty].id;
          //     polyOption.innerHTML = self.generatedData.lines[ty].name;
          //     polyOption.name = self.generatedData.lines[ty].name;
          //     if (self.generatedData.lines[ty].name === selection.name) {
          //         polyOption.selected = true;
          //     }
          //     document.getElementById('combo_polygons').appendChild(polyOption);
          // }
          var pointCombos = document.getElementById("pointonLineDiv").querySelectorAll(".point-combo1");

          var interSections = document.getElementById("combo_start_pt");
          while (interSections.firstChild) {
            interSections.removeChild(interSections.firstChild);
          }

          for (var i = 0; i < pointsData.length; i++) {
            var nm = pointsData[i]["name"];
            var x = pointsData[i]["coordinate"][0];
            var y = pointsData[i]["coordinate"][1];
            var z = pointsData[i]["coordinate"].length > 2 ? pointsData[i]["coordinate"][2] : 0;
            var opt = document.createElement("option");
            opt.value = i;
            opt.setAttribute("x", x);
            opt.setAttribute("y", y);
            opt.setAttribute("z", z);
            opt.innerHTML = nm;
            pointCombos[0].appendChild(opt);
          }
          // self.labelRadioTowards('label_rb_1', 'label_rb_2', data.value, index);

          // var li = 0;

          // document.getElementById('combo_polygons').addEventListener("change", function (event) {
          //     var selname = document.getElementById('combo_polygons').options[document.getElementById('combo_polygons').selectedIndex].text;
          //     self.generatPointsData(this.value, selname);
          // });
          document.getElementById("linePointClose").addEventListener("click", function (evt) {
            self.panelPointOnLine.close();
            self.panelPointOnLine = null;
          });
          document.getElementById("add_point").addEventListener("click", function (evt) {
            var index;
            var polyId = document.getElementById("combo_polygons").options[document.getElementById("combo_polygons").selectedIndex].value;
            var polyName = document.getElementById("combo_polygons").options[document.getElementById("combo_polygons").selectedIndex].text;
            for (var i = 0; i < self.generatedData.lines.length; i++) {
              if (self.generatedData.lines[i].id === polyId && self.generatedData.lines[i].name === polyName) {
                index = i;
              }
            }
            let li = 0;
            let idx = Number(document.getElementById("combo_start_pt").value);
            let forward = true;
            var tempCombos = document.getElementsByName("rb_start");
            var isforward = document.getElementById("comCheck");
            if (isforward.checked) {
              forward = false;
            }
            let d = 0;
            if (!isNaN(document.getElementById("number_length1").value)) {
              d = Number(document.getElementById("number_length1").value) * self.options.scaleFactor;
            }
            let cord = self.boundaryPointAtDistance(index, idx, d, forward, true);

            var desiredSegment;
            if (cord.availablePoints.length === 2) {
              for (var t = 0; t < self.generatedData.linesegments.length; t++) {
                if (
                  self.generatedData.linesegments[t].parentNodes.includes(cord.availablePoints[0].id) &&
                  self.generatedData.linesegments[t].parentNodes.includes(cord.availablePoints[1].id) &&
                  self.generatedData.linesegments[t].parentid === selection.id
                ) {
                  desiredSegment = self.generatedData.linesegments[t];
                }
              }
            }
            if (cord !== null) {
              if (confirm("Do you want to perform cut segment?")) {
                let ptName = document.getElementById("pt_name").value;
                self.cutSegmentwithPoint(desiredSegment, cord.availablePoints, ptName, cord.distance);
                self.clearTempGeom();
              } else {
                let ptName = document.getElementById("pt_name").value;
                if (self.digitizer.options.dimensions === 3 && cord.coordinate.length < 3) {
                  cord.coordinate.push(0);
                }
                let pt = new Point(cord.coordinate);
                let wktPt = self._wktFormat.writeGeometry(pt);

                // let snapped = self.snapPointToBoundary(self._jstsReader.read(wktPt));
                // wktPt = snapped.toText();

                let pointData = {
                  name: ptName,
                  geom: wktPt,
                  type: Gis.POINTTYPE.CREATED,
                  idx: 1,
                };
                self.preCreatePoint(pointData);
                self.clearTempGeom();
              }
            }
            li++;
            self.panelPointOnLine.close();
            self.panelPointOnLine = null;
          });
        },
      },
      position: "left-bottom 5 -50",
      contentSize: "280 175",
      borderRadius: ".5rem",
      theme: "dark",
      container: "div.bn-container",
      headerControls: { size: "xs" }, // shorthand
      onclosed: function (panel, closedByUser) {
        self.panelPointOnLine = null;
        self.clearTempGeom();
        self.clearSelectedButton();
      },
    });
    // }
  }

  cutSegment(startCoord, endCoord, midCoord) {
    var self = this;
    if (self.cutSegmentUI && self.cutSegmentUI !== null) {
      self.cutSegmentUI.close((id) => {
        self.cutSegmentUI = null;
      });
    }
    // document.getElementById("infoPanel").style.display = "block";
    // document.getElementById("infoPanel").innerHTML = "Select a Segment";
    // // var selection = await self.pickDataFromMap(Gis.LAYERTYPE.SEGMENT);
    // // if (selection) {
    // document.getElementById("infoPanel").innerHTML = "";
    // document.getElementById("infoPanel").style.display = "none";
    self.cutSegmentUI = jsPanel.create({
      headerTitle: "Cut Segment",
      contentFetch: {
        resource: template_cut_segment,
        done: function (response, panel) {
          panel.contentRemove();
          panel.content.append(jsPanel.strToHtml(response));

          let availablePoints = self.digitizer.generatePointsData([startCoord, endCoord]);
          // for (var i = 0; i < self.generatedData.points.length; i++) {
          //     for (var j = 0; j < selection.parentNodes.length; j++) {
          //         if (selection.parentNodes[j] === self.generatedData.points[i].id) {
          //             availablePoints.push(self.generatedData.points[i]);
          //         }
          //     }
          // }
          if (availablePoints.length === 2) {
            document.getElementById("label_rb_1").innerHTML = availablePoints[0].name;
            document.getElementById("rb_1").setAttribute("X", startCoord[0]);
            document.getElementById("rb_1").setAttribute("Y", startCoord[1]);
            document.getElementById("rb_1").setAttribute("Z", !isNaN(startCoord[2]) ? startCoord[2] : 0);

            document.getElementById("label_rb_2").innerHTML = availablePoints[1].name;
            document.getElementById("rb_2").setAttribute("X", endCoord[0]);
            document.getElementById("rb_2").setAttribute("Y", endCoord[1]);
            document.getElementById("rb_2").setAttribute("Z", !isNaN(endCoord[2]) ? endCoord[2] : 0);

            document.getElementById("cutpoint_distance").value = "" + coordinateDistance(startCoord, midCoord).toFixed(2);
          }

          document.getElementById("rb_1").addEventListener("change", function (evt) {
            self.showCutPoint();
          });

          document.getElementById("rb_2").addEventListener("change", function (evt) {
            self.showCutPoint();
          });

          document.getElementById("cutpt_name").addEventListener("change", function (evt) {
            self.showCutPoint();
          });

          //document.getElementById('choosedLineName').innerHTML = selection.name;
          document.getElementById("cutpoint_distance").addEventListener("change", function (evt) {
            self.showCutPoint();
          });

          document.getElementById("cutPointClose").addEventListener("click", function (evt) {
            self.cutSegmentUI.close();
            self.digitizer.clearTempGeom();
          });
          document.getElementById("add_cutpoint").addEventListener("click", function (evt) {
            var midCoord = self.showCutPoint();
            var undoItemGroup = [];
            self.digitizer.insertPointInSegment(startCoord, endCoord, midCoord, undoItemGroup);
            self.digitizer.undoRedoManager.pushToUndoStack(undoItemGroup);

            self.cutSegmentUI.close();
            self.digitizer.clearTempGeom();
          });
          self.showCutPoint();
        },
      },
      position: "left-bottom 5 -50",
      contentSize: "320 190",
      borderRadius: ".5rem",
      theme: "dark",
      container: "div.bn-container",
      headerControls: { size: "xs" }, // shorthand
      onclosed: function (panel, closedByUser) {
        self.cutSegmentUI = null;
        self.digitizer.clearTempGeom();
        self.clearSelectedButton();
      },
    });
    // }
  }

  createAnglePoint() {
    var self = this;
    if (self.anglePointUI && self.anglePointUI !== null) {
      self.anglePointUI.close();
      self.anglePointUI = null;
    }
    self.anglePointUI = jsPanel.create({
      headerTitle: "Create angle Point",
      closeOnEscape: true,
      contentFetch: {
        resource: template_angle_point,
        done: function (response, panel) {
          panel.contentRemove();
          panel.content.append(jsPanel.strToHtml(response));
          document.getElementById("angleUIClose").addEventListener("click", function (evt) {
            self.anglePointUI.close();
            self.anglePointUI = null;
            self.digitizer.clearTempGeom();
          });
          let combo1 = document.getElementById("angle_combo_start_pt");
          let combo2 = document.getElementById("angle_combo_end_pt");

          for (var i = 0; i < self.digitizer.generatedData.points.length; i++) {
            let nm = self.digitizer.generatedData.points[i]["name"];
            let id = self.digitizer.generatedData.points[i]["id"];
            var pOptions1 = document.createElement("option");
            pOptions1.value = id;
            pOptions1.name = nm;
            pOptions1.innerHTML = nm;
            combo1.appendChild(pOptions1);

            var pOptions2 = document.createElement("option");
            pOptions2.value = id;
            pOptions2.name = nm;
            pOptions2.innerHTML = nm;
            combo2.appendChild(pOptions2);
          }

          var virtualPoints = document.getElementById("anlePointUI").querySelectorAll(".point-combo");
          virtualPoints.forEach(function (data, i) {
            data.addEventListener("change", function (evt) {
              self.anglePointCreateVirtualLine();
            });
          });
          document.getElementById("pointDistance").addEventListener("change", function (evt) {
            self.genAnglePoint();
          });
          document.getElementById("pointAngle").addEventListener("change", function (evt) {
            self.genAnglePoint();
          });
          document.getElementById("angleUISave").addEventListener("click", function (evt) {
            self.genAnglePoint(true);
            self.digitizer.addPointsFromTemp("PT");
            self.digitizer.clearTempGeom();
            self.anglePointUI.close();
            self.anglePointUI = null;
          });
          document.getElementById("angptDistincrease").addEventListener("click", function (evt) {
            document.getElementById("pointDistance").value = Number(document.getElementById("pointDistance").value) + 0.5;
            self.genAnglePoint();
          });
          document.getElementById("angptDistdecrease").addEventListener("click", function (evt) {
            if (Number(document.getElementById("pointDistance").value) > 0) {
              document.getElementById("pointDistance").value = Number(document.getElementById("pointDistance").value) - 0.5;
              self.genAnglePoint();
            }
          });
          document.getElementById("angptAngleIncrease").addEventListener("click", function (evt) {
            if (Number(document.getElementById("pointAngle").value) < 360) {
              document.getElementById("pointAngle").value = Number(document.getElementById("pointAngle").value) + 0.5;
              self.genAnglePoint();
            }
          });
          document.getElementById("angptAngleDecrease").addEventListener("click", function (evt) {
            if (Number(document.getElementById("pointAngle").value) > 0) {
              document.getElementById("pointAngle").value = Number(document.getElementById("pointAngle").value) - 0.5;
              self.genAnglePoint();
            }
          });
        },
      },
      position: "left-bottom 5 -50",
      theme: "dark ",
      contentSize: "310 180",
      borderRadius: ".5rem",
      container: "div.bn-container",
      headerControls: { size: "xs" }, // shorthand
      onclosed: function (panel, closedByUser) {
        self.anglePointUI = null;
        self.digitizer.clearTempGeom();
        self.clearSelectedButton();
      },
    });
  }

  genAnglePoint(ptDistance, ptAngleDegree, pt1ID, pt2ID) {
    var ptDistance = Number(document.getElementById("pointDistance").value) * this.options.scaleFactor;
    var ptAngleDegree = Number(document.getElementById("pointAngle").value);

    var pt1ID = document.getElementById("angle_combo_start_pt").options[document.getElementById("angle_combo_start_pt").selectedIndex].value;
    var pt2ID = document.getElementById("angle_combo_end_pt").options[document.getElementById("angle_combo_end_pt").selectedIndex].value;

    this.anglePointCreateVirtualLine();

    this.digitizer.createAnglePoint(ptDistance, ptAngleDegree, pt1ID, pt2ID);
  }

  createOffsetPointUI() {
    var self = this;
    if (self.ladderPointUI && self.ladderPointUI !== null) {
      self.ladderPointUI.close();
      self.ladderPointUI = null;
    }
    self.ladderPointUI = jsPanel.create({
      headerTitle: "Create offset Point",
      closeOnEscape: true,
      contentFetch: {
        resource: template_ladder_point,
        done: function (response, panel) {
          panel.contentRemove();
          panel.content.append(jsPanel.strToHtml(response));

          let combo1 = document.getElementById("ladder_combo_start_pt");
          let combo2 = document.getElementById("ladder_combo_end_pt");

          for (var i = 0; i < self.digitizer.generatedData.points.length; i++) {
            let nm = self.digitizer.generatedData.points[i]["name"];
            let id = self.digitizer.generatedData.points[i]["id"];
            var pOptions1 = document.createElement("option");
            pOptions1.value = id;
            pOptions1.name = nm;
            pOptions1.innerHTML = nm;
            combo1.appendChild(pOptions1);

            var pOptions2 = document.createElement("option");
            pOptions2.value = id;
            pOptions2.name = nm;
            pOptions2.innerHTML = nm;
            combo2.appendChild(pOptions2);
          }

          document.getElementById("LadderClose").addEventListener("click", function () {
            self.digitizer.clearTempGeom();
            self.ladderPointUI.close();
            self.ladderPointUI = null;
          });
          document.getElementById("ladder_combo_start_pt").addEventListener("change", function (evt) {
            //self.offsetPointCreateVirtualLine();
            self.genOffsetTempPoint();
          });
          document.getElementById("ladder_combo_end_pt").addEventListener("change", function (evt) {
            //self.offsetPointCreateVirtualLine();
            self.genOffsetTempPoint();
          });
          document.getElementById("ladderDistance").addEventListener("change", function (evt) {
            self.genOffsetTempPoint();
          });
          document.getElementById("pointDistance").addEventListener("change", function (evt) {
            self.genOffsetTempPoint();
          });
          document.getElementById("offsetPointDirection").addEventListener("change", function (evt) {
            self.genOffsetTempPoint();
          });
          document.getElementById("LadderSave").addEventListener("click", function (evt) {
            self.genOffsetTempPoint();
            self.digitizer.addPointsFromTemp("PT");
            self.digitizer.clearTempGeom();
            self.ladderPointUI.close();
            self.ladderPointUI = null;
          });
          document.getElementById("ldDistincrease").addEventListener("click", function (evt) {
            document.getElementById("ladderDistance").value = Number(document.getElementById("ladderDistance").value) + 0.5;
            self.genOffsetTempPoint();
          });
          document.getElementById("ldDistdecrease").addEventListener("click", function (evt) {
            if (document.getElementById("ladderDistance").value > 0) {
              document.getElementById("ladderDistance").value = Number(document.getElementById("ladderDistance").value) - 0.5;
              self.genOffsetTempPoint();
            }
          });
          document.getElementById("ptDistanceDecrease").addEventListener("click", function (evt) {
            document.getElementById("pointDistance").value = Number(document.getElementById("pointDistance").value) + 0.5;
            self.genOffsetTempPoint();
          });
          document.getElementById("ptDistanceIncrease").addEventListener("click", function (evt) {
            if (document.getElementById("pointDistance").value > 0) {
              document.getElementById("pointDistance").value = Number(document.getElementById("pointDistance").value) - 0.5;
              self.genOffsetTempPoint();
            }
          });
        },
      },
      position: "left-bottom 5 -50",
      theme: "dark ",
      contentSize: "290 230",
      borderRadius: ".5rem",
      container: "div.bn-container",
      headerControls: { size: "xs" }, // shorthand
      onclosed: function (panel, closedByUser) {
        self.ladderPointUI = null;
        self.digitizer.clearTempGeom();
        self.clearSelectedButton();
      },
    });
  }

  anglePointCreateVirtualLine() {
    var self = this;
    var pt1ID = document.getElementById("angle_combo_start_pt").options[document.getElementById("angle_combo_start_pt").selectedIndex].value;
    var pt1Name = document.getElementById("angle_combo_start_pt").options[document.getElementById("angle_combo_start_pt").selectedIndex].text;
    var pt2ID = document.getElementById("angle_combo_end_pt").options[document.getElementById("angle_combo_end_pt").selectedIndex].value;
    var pt2Name = document.getElementById("angle_combo_end_pt").options[document.getElementById("angle_combo_end_pt").selectedIndex].text;
    self.digitizer.clearTempGeom();
    self.digitizer.createTempLine(pt1ID, pt2ID, "L");
  }

  offsetPointCreateVirtualLine() {
    var self = this;
    var pt1ID = document.getElementById("ladder_combo_start_pt").options[document.getElementById("ladder_combo_start_pt").selectedIndex].value;
    var pt1Name = document.getElementById("ladder_combo_start_pt").options[document.getElementById("ladder_combo_start_pt").selectedIndex].text;
    var pt2ID = document.getElementById("ladder_combo_end_pt").options[document.getElementById("ladder_combo_end_pt").selectedIndex].value;
    var pt2Name = document.getElementById("ladder_combo_end_pt").options[document.getElementById("ladder_combo_end_pt").selectedIndex].text;
    self.digitizer.clearTempGeom();
    self.digitizer.createTempLine(pt1ID, pt2ID, "L");
  }

  genOffsetTempPoint(generate) {
    var pt1ID = document.getElementById("ladder_combo_start_pt").options[document.getElementById("ladder_combo_start_pt").selectedIndex].value;
    var pt1y = document.getElementById("ladder_combo_start_pt").options[document.getElementById("ladder_combo_start_pt").selectedIndex].y;
    var pt1Name = document.getElementById("ladder_combo_start_pt").options[document.getElementById("ladder_combo_start_pt").selectedIndex].text;
    var pt2ID = document.getElementById("ladder_combo_end_pt").options[document.getElementById("ladder_combo_end_pt").selectedIndex].value;
    var pt2y = document.getElementById("ladder_combo_end_pt").options[document.getElementById("ladder_combo_end_pt").selectedIndex].y;
    var pt2Name = document.getElementById("ladder_combo_end_pt").options[document.getElementById("ladder_combo_end_pt").selectedIndex].text;

    var direction = document.getElementById("offsetPointDirection").options[document.getElementById("offsetPointDirection").selectedIndex].value;
    var ladderDistance = Number(document.getElementById("ladderDistance").value) * this.options.scaleFactor;
    var offsetDistance = Number(document.getElementById("pointDistance").value) * this.options.scaleFactor;
    let ptName = document.getElementById("offsetPointDistance").value;

    this.digitizer.clearTempGeom();
    this.offsetPointCreateVirtualLine();

    this.digitizer.createOffsetPoint(pt1ID, pt2ID, ladderDistance, offsetDistance, ptName, direction);
  }

  showCutPoint() {
    let self = this;
    var ptName = document.getElementById("cutpt_name").value;
    var ptDist = Number(document.getElementById("cutpoint_distance").value);
    var startCoord = [Number(document.getElementById("rb_1").getAttribute("X")), Number(document.getElementById("rb_1").getAttribute("Y"))];
    if (!isNaN(document.getElementById("rb_1").getAttribute("Z"))) {
      startCoord[2] = Number(document.getElementById("rb_1").getAttribute("Z"));
    }
    var endCoord = [Number(document.getElementById("rb_2").getAttribute("X")), Number(document.getElementById("rb_2").getAttribute("Y"))];
    if (!isNaN(document.getElementById("rb_2").getAttribute("Z"))) {
      endCoord[2] = Number(document.getElementById("rb_2").getAttribute("Z"));
    }
    var midCoord = self.digitizer.cutPointAtDistance(startCoord, endCoord, ptDist, document.getElementById("rb_1").checked);
    self.digitizer.showCutPoint(startCoord, endCoord, midCoord, ptName);
    return midCoord;
  }


  showInfoPanel(header, content, options1 = {}) {
    var self = this;
    if (self.infoPanel && self.infoPanel !== null) {
      self.infoPanel.close();
    }

    let options = {
      headerTitle: "Map Info",
      content: '<div id="plot_info_content"></div>',
      position: "left-bottom 5 50",
      contentSize: "300 410",
      container: "div.bn-container",
      minimizeTo: "parent",
      syncMargins: true,
      dragit: { snap: true },
      // theme: "#181c32 filled",
      theme: {
        bgContent: "#ffff",
        bgFooter: "#000",
        bgPanel: "#000000",
        colorHeader: "#FFF",
      },

      onclosed: function (panel, closedByUser) {
        self.infoPanel = null;
      },
      // bgFooter:"#181c32",
      footerToolbar: "<span>&nbsp;</span>",
      headerControls: {
        maximize: "remove",
        size: "xs",
      },
      panelSize: {
        width: () => {
          if (window.screen.width < 500) {
            return "95%";
          } else if (window.screen.width < 800) {
            return "90%";
          } else {
            return 370;
          }
        },
        height: () => {
          if (window.screen.width < 500) {
            return "30vh";
          } else if (window.screen.width < 800) {
            return "35vh";
          } else {
            return 600;
          }
        },
      },
    };

    options = Object.assign(options, options1, {
      headerTitle: header,
      content: content,
    });
    console.log(options);
    self.infoPanel = jsPanel.create(options);
  }

  showPointsForExport() {
    var self = this;

    if (self.exportPointsPanel && self.exportPointsPanel !== null) {
      self.exportPointsPanel.close();
    }

    let data = [];
    for (let i = 0; i < self.digitizer.generatedData.points.length; i++) {
      let geom = self.digitizer._wktFormat.readGeometry(self.digitizer.generatedData.points[i].geom);
      let coord = geom.getCoordinates();

      data.push({
        name: self.digitizer.generatedData.points[i].name,
        x: coord[0],
        y: coord[1],
        z: coord.length > 2 ? coord[2] : 0,
      });
    }

    const tableConfig = {
      deleteControls: false,
      zoomControls: false,
      addRowButton: false,
      footerControls: true,
      onRowSelected: function (idx) {},
      columnConfig: {
        name: { visible: true, header: "Name" },
        x: { visible: true, header: "X" },
        y: { visible: true, header: "Y" },
        z: { visible: true, header: "Z" },
      },
    };

    let options = {
      headerTitle: "Map Info",
      content: '<div id="_export_point_grid" class="_export_point_grid" style="height:400"></div>',
      position: "left-center 5 5",
      contentSize: "360 410",
      container: "div.bn-container",
      minimizeTo: "parent",
      syncMargins: true,
      dragit: { snap: true },
      // theme: "#181c32 filled",
      theme: {
        bgContent: "#ffff",
        bgFooter: "#000",
        bgPanel: "#000000",
        colorHeader: "#FFF",
      },

      onclosed: function (panel, closedByUser) {
        self.exportPointsPanel = null;
        self.clearSelectedButton();
      },
      // bgFooter:"#181c32",
      footerToolbar: ['<span class="jsPanel-ftr-btn export" style="cursor:pointer" title="Export CSV"><i class="fg fg-multipoint fg-lg  fg-white"></i></span>'],
      headerControls: {
        maximize: "remove",
        size: "xs",
      },
      callback: (panel) => {
        const editableTable = new EditableTable(data, tableConfig, "_export_point_grid");

        // const grid = new Grid({
        //   usageStatistics: false,
        //   el: panel.content.getElementsByClassName("_export_point_grid")[0],
        //   data: data,
        //   // rowHeaders: ['rowNum', 'checkbox'],
        //   scrollX: false,
        //   scrollY: false,
        //   columns: [
        //     {
        //       header: "Name",
        //       name: "name",
        //     },
        //     {
        //       header: "X",
        //       name: "x",
        //     },
        //     {
        //       header: "Y",
        //       name: "y",
        //     },
        //     {
        //       header: "Z",
        //       name: "z",
        //     },
        //   ],
        // });

        // // handler for the icons
        // for (const btn of panel.footer.querySelectorAll("span")) {
        //   btn.addEventListener("click", (e) => {
        //     let cl = e.target.closest("span").classList;
        //     if (cl[cl.length - 1] === "export") {
        //       grid.export("csv");
        //     }
        //   });
        // }
      },
      panelSize: {
        width: () => {
          if (window.screen.width < 500) {
            return "95%";
          } else if (window.screen.width < 800) {
            return "90%";
          } else {
            return 370;
          }
        },
        height: () => {
          if (window.screen.width < 500) {
            return "30vh";
          } else if (window.screen.width < 800) {
            return "35vh";
          } else {
            return 600;
          }
        },
      },
    };

    self.exportPointsPanel = jsPanel.create(options);
  }

  showFeatureProperties(layerId, data) {
    if (data.length < 1) {
      return;
    }

    const self = this;

    let layerName = self.digitizer.findLayerById(layerId).get("name");
    let headerTitle = "Map Attributes - " + layerName;

    if (self.featurePropertiesPanel && self.featurePropertiesPanel !== null) {
      self.featurePropertiesPanel.close();
    }

    let columns = [];
    let grid = null;
    Object.keys(data[0]).forEach((key) => {
      if (key !== "__id") {
        columns[key] = {
          header: titleCase(key),
          editable: false,
          visible: true,
        };
      } else {
        columns[key] = {
          header: titleCase(key),
          editable: false,
          visible: false,
        };
      }
    });

    let options = {
      headerTitle: headerTitle,
      content: '<div id="_feature_properties_panel" class="_feature_properties_panel" style="height:100%; width: auto;"></div>',
      position: "left-center 5 5",
      contentSize: "360 670",
      container: "div.bn-container",
      minimizeTo: "parent",
      syncMargins: true,
      dragit: { snap: true },
      // theme: "#181c32 filled",
      theme: {
        bgContent: "#ffff",
        bgFooter: "#000",
        bgPanel: "#000000",
        colorHeader: "#FFF",
      },

      onclosed: function (panel, closedByUser) {
        self.featurePropertiesPanel = null;
        self.clearSelectedButton();
      },
      // bgFooter:"#181c32",
      footerToolbar: ['<span class="jsPanel-ftr-btn export" style="cursor:pointer" title="Export CSV"><i class="fg fg-multipoint fg-lg  fg-white"></i></span>'],
      headerControls: {
        maximize: "remove",
        size: "xs",
      },
      resizeit: {
        stop: () => {
          // grid.refreshLayout();
          // let parentDiv = document.getElementsByClassName("_feature_properties_panel")[0].parentNode.parentNode;
          // let parentHeightInPixels = parentDiv.getBoundingClientRect().height - 50;
          // grid.setBodyHeight(parentHeightInPixels);
        },
      },
      callback: (panel) => {
        const tableConfig = {
          deleteControls: false,
          footerControls: false,
          zoomControls: true,

          onRowSelected: function (idx) {
            self.digitizer.focusToLayerFeature(layerId, data[idx].__id);
          },
          columnConfig: columns,
        };

        const editableTable = new EditableTable(data, tableConfig, "_feature_properties_panel");

        // grid = new Grid({
        //   usageStatistics: false,
        //   el: panel.content.getElementsByClassName("_feature_properties_panel")[0],
        //   data: data,
        //   rowHeaders: ["rowNum"],
        //   // pageOptions: {
        //   //     useClient: true,
        //   //     perPage: 5
        //   //  },
        //   // rowHeaders: ['rowNum', 'checkbox'],
        //   scrollX: true,
        //   scrollY: true,
        //   columns: columns,
        // });

        // let parentDiv = document.getElementsByClassName("_feature_properties_panel")[0].parentNode.parentNode;
        // let parentHeightInPixels = parentDiv.getBoundingClientRect().height - 50;
        // grid.setBodyHeight(parentHeightInPixels);

        // // handler for the icons
        // for (const btn of panel.footer.querySelectorAll("span")) {
        //   btn.addEventListener("click", (e) => {
        //     let cl = e.target.closest("span").classList;
        //     if (cl[cl.length - 1] === "export") {
        //       grid.export("csv");
        //     }
        //   });
        // }
        // //grid.hideColumn("geometry");
        // grid.on("focusChange", (evt) => {
        //   let row = grid.getRow(evt.rowKey);
        //   self.digitizer.focusToLayerFeature(layerId, row.__id);
        //   // self.digitizer.getMap().getView().fit(row.geometry, {padding: [170, 50, 30, 150]})
        // });
      },
      panelSize: {
        width: () => {
          if (window.screen.width < 500) {
            return "95%";
          } else if (window.screen.width < 800) {
            return "90%";
          } else {
            return 670;
          }
        },
        height: () => {
          if (window.screen.width < 500) {
            return "30vh";
          } else if (window.screen.width < 800) {
            return "35vh";
          } else {
            return 600;
          }
        },
      },
    };

    self.featurePropertiesPanel = jsPanel.create(options);
  }

  createJsPanel() {
    return jsPanel;
  }
  // createGrid(options) {
  //   return new Grid(options);
  // }
}

export { UIBuilder };
