/*

      // Sample data as a generic object
      const initialData = [
        { name: "Alice", age: 25, nonVisibleField: "Hidden" , nonEditableField: "Many"},
        { name: "Bob", age: 30, nonVisibleField: "Secret" , nonEditableField: "Many"},
        { name: "Charlie", age: 28, nonVisibleField: "Private" , nonEditableField: "Many"},
        { name: "David", age: 35, nonVisibleField: "Private", nonEditableField: "Many" },
      ];

      // Custom column configuration

      const tableConfig = {
        deleteControls: false,
        footerControls: false,
        zoomControls: true,
        onDataChanged:function(index,field){console.log(initialData[index][field])},
        onRowSelected: function(idx) { console.log(initialData[idx])},
        columnConfig: {
          name: { visible: true, header: "Full Name" },
          age: { visible: true, header: "Age" },
          nonVisibleField: { visible: false, header: "" },
          nonEditableField: { editable: false, header: "Non Editable" },
        },
      };

*/

class EditableTable {
    constructor(initialData, tableConfig, containerId) {
      this.initialData = initialData;
      this.columnConfig = tableConfig.columnConfig;
      this.tableConfig = tableConfig;
      this.containerId = containerId;
      this.tableBody = null;
      this.addRowButton = null;
      this.exportButton = null;
      this.setup();
    }

    injectStyles() {
      const styles = `
  #${this.containerId}_g {
    overflow-x:auto;
    /*  overflow: auto; Add overflow property to show scrollbar */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: white;
    height: 100%;
    
    /* max-height: 400px;  Set a maximum height for the container */
  }
  #${this.containerId}_g table {
   border-collapse: collapse;
    
   
  }
  #${this.containerId}_g th, #${this.containerId} td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  #${this.containerId}_g th {
    background-color: #f2f2f2;
  }
  #${this.containerId}_g td {
    background-color: white;
  }
  #${this.containerId}_g .delete-column {
    width: 50px; /* Adjust the width as needed */
  }
  #${this.containerId}_g .delete-button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 5px;
    border-radius: 3px;
    cursor: pointer;
  }
  #${this.containerId}_g .view-button {
    background-color: #C0C0C0;
    color: white;
    border: none;
    padding: 5px;
    border-radius: 3px;
    cursor: pointer;
  }
  #${this.containerId}_g #addRowButton {
    display: block;
    margin: 10px auto;
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  #${this.containerId}_g #exportButton {
    display: block;
    margin: 10px auto;
    background-color: #2196F3;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
`;

      this.styleSheet = document.createElement("style");
      this.styleSheet.type = "text/css";
      this.styleSheet.innerText = styles;
      document.head.appendChild(this.styleSheet);
    }

    populateTable() {
      const headerRow = document.querySelector(`#${this.containerId}_g #headerRow`);
      headerRow.innerHTML = "";

      for (const field in this.columnConfig) {
        if (this.columnConfig[field].visible!==false) {
          const th = document.createElement("th");
          th.textContent = this.columnConfig[field].header;
          headerRow.appendChild(th);
        }
      }

      this.tableBody.innerHTML = "";
      this.initialData.forEach((item, index) => {
        const row = document.createElement("tr");
        row.setAttribute("_id",index);
        for (const field in this.columnConfig) {
          if (this.columnConfig[field].visible !== false) {
            const cell = document.createElement("td");
            if (this.columnConfig[field].editable!==false) {
            cell.contentEditable = true;
            }
            cell.dataset.property = field;
            cell.textContent = item[field];
            row.appendChild(cell);
          }
        }

        const deleteCell = document.createElement("td");
        deleteCell.className = "delete-column";
        const deleteButton = document.createElement("button");
        deleteButton.textContent = "⨂";
        deleteButton.className = "delete-button";
        deleteButton.addEventListener("click", () => {
          this.initialData.splice(index, 1);
          this.populateTable();
        });
      
        if(this.tableConfig.deleteControls===true){
        deleteCell.appendChild(deleteButton);
    }

    const viewButton = document.createElement("button");
    viewButton.textContent = '🔍';
    viewButton.className = "view-button";
    viewButton.addEventListener("click", () => {
      this.selectRow(viewButton.closest("tr"));
    });
    if(this.tableConfig.zoomControls===true){
    deleteCell.appendChild(viewButton);
    }
        row.appendChild(deleteCell);
        

        this.tableBody.appendChild(row);
        // this.tableBody.querySelectorAll('tr').forEach(row => {
        //     row.addEventListener('click', () => {
              
        //       this.selectRow(row);
        //     });
        //   });
      });
    }

    selectRow(row) {
        if (this.selectedRow) {
          this.selectedRow.classList.remove('selected');
        }
        this.selectedRow = row;
        row.classList.add('selected');
        if(this.tableConfig.onRowSelected){
            this.tableConfig.onRowSelected(Number(row.getAttribute("_id")));
        }
      }
    
    updateDataFromTable() {
      const rows = this.tableBody.querySelectorAll("tr");
      const updatedData = [...this.initialData]; // Clone the initialData array

      rows.forEach((row, index) => {
        for (const field in this.columnConfig) {
          if (this.columnConfig[field].visible!==false) {
            updatedData[index][field] = row.querySelector(`[data-property="${field}"]`).textContent;
          }
        }
      });

      this.initialData = updatedData;
      console.log(this.initialData);
    }
 
    exportToCSV() {
      const csvContent =
        "data:text/csv;charset=utf-8," +
        this.initialData
          .map((row) => {
            const escapedRow = Object.values(row)
              .map((value) => {
                if (typeof value === "string" && value.includes(",")) {
                  return `"${value}"`;
                }
                return value;
              })
              .join(",");
            return escapedRow;
          })
          .join("\n");

      const encodedURI = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedURI);
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    setup() {
      const self = this;
      this.injectStyles();

      const tableContainer = document.createElement("div");
      tableContainer.id = this.containerId + "_g"; // Use the provided containerId

      const table = document.createElement("table");
      table.id = "editableTable";

      const thead = document.createElement("thead");
      thead.innerHTML = '<tr id="headerRow"></tr>';
      table.appendChild(thead);

      const tbody = document.createElement("tbody");
      table.appendChild(tbody);

      tableContainer.appendChild(table);

      this.tableBody = tbody;

      this.addRowButton = document.createElement("button");
      this.addRowButton.textContent = "Add Row ⨁";
      this.addRowButton.id = "addRowButton";
      this.addRowButton.addEventListener("click", () => {
        const newRow = {};
        for (const field in this.columnConfig) {
          newRow[field] = ""; // Initialize with empty values
        }
        this.initialData.push(newRow);
        this.populateTable();
      });

      this.exportButton = document.createElement("button");
      this.exportButton.textContent = "Export CSV";
      this.exportButton.id = "exportButton";
      this.exportButton.addEventListener("click", () => {
        this.exportToCSV();
      });

      if(this.tableConfig.footerControls===true){
        if(this.tableConfig.addRowButton!==false){
         tableContainer.appendChild(this.addRowButton);
        }
        if(this.tableConfig.exportButton!==false){

         tableContainer.appendChild(this.exportButton);
        }
      }
     

      // Append the table container to the specified container by ID
      const container = document.getElementById(this.containerId);
      if (container) {
        container.appendChild(tableContainer);
      }

      this.populateTable();

      this.tableBody.addEventListener("input", (e) => {

        this.updateDataFromTable();
        if(self.tableConfig.onDataChanged){
          self.tableConfig.onDataChanged(e.target.closest("tr").getAttribute("_id"), e.target.dataset.property , self.getData());
        }
        
      });
    }

    getData() {
      return this.initialData;
    }

    destroy() {
      const container = document.getElementById(this.containerId + "_g");

      if (container) {
        // Remove event listeners
        this.addRowButton.removeEventListener("click", this.addButtonClickHandler);
        this.exportButton.removeEventListener("click", this.exportButtonClickHandler);

        // Remove the table and container

        // Remove the injected styles
        if (this.styleSheet && this.styleSheet.parentNode) {
          this.styleSheet.parentNode.removeChild(this.styleSheet);
        }
        container.remove();
      }
    }
  }

  export { EditableTable }