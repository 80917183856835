class CustomSelect {
    constructor(targetId, optionsData) {
        this.targetId = targetId;
        this.target = document.getElementById(targetId);
        this.optionsData = optionsData;
        this.select = null;
        this.selectBox = null;
        this.selectOptions = null;
        this.searchInput = null;
        this.selectedValue = null;

        this.initialize();
    }

    initialize() {
        if (!this.target) {
            console.error(`Target element with ID "${this.targetId}" not found.`);
            return;
        }

        this.createCustomSelectStructure();
    }

    createCustomSelectStructure() {
        this.select = document.createElement('select');
        this.selectBox = document.createElement('div');
        this.selectOptions = document.createElement('div');

        this.select.className = 'custom-select';
        this.selectBox.className = 'select-box';
        this.selectOptions.className = 'select-options';

        this.target.appendChild(this.select);
        this.target.appendChild(this.selectBox);
        this.target.appendChild(this.selectOptions);

        
        this.createSearchInput();
        this.populateSelectOptions();
        this.addEventListeners();

        const firstOption = this.select.querySelector('option');
        this.selectedValue = firstOption.value;
        this.select.value = this.selectedValue;
        this.selectBox.innerHTML = `<img src="${firstOption.getAttribute('data-image')}" alt="${firstOption.textContent}"> ${firstOption.textContent}`;
    }

    createSearchInput() {
        this.searchInput = document.createElement('input');
        this.searchInput.type = 'text';
        this.searchInput.placeholder = 'Search...';
        this.searchInput.style.backgroundColor = '#333'; // Dark background color
        this.searchInput.style.color = '#fff'; // Light text color
        this.searchInput.style.border = '1px solid #555'; // Dark border color
        this.searchInput.style.width = '100%';
        this.searchInput.addEventListener('input', () => this.filterOptions());
        this.selectOptions.appendChild(this.searchInput);
    }

    filterOptions() {
        const searchTerm = this.searchInput.value.toLowerCase();
        const optionDivs = this.selectOptions.querySelectorAll('.select-option');

        optionDivs.forEach((optionDiv) => {
            const textContent = optionDiv.textContent.toLowerCase();
            const display = textContent.includes(searchTerm) ? 'block' : 'none';
            optionDiv.style.display = display;
        });
    }

    populateSelectOptions() {
        for (const optionData of this.optionsData) {
            const option = document.createElement('option');
            option.value = optionData.value;
            option.setAttribute('data-image', optionData.image);
            option.textContent = optionData.text;
            option.setAttribute('geomtype', optionData.geomtype);
            this.select.appendChild(option);

            const optionDiv = this.createOptionDiv(option);
            this.selectOptions.appendChild(optionDiv);
        }
    }

    createOptionDiv(option) {
        const value = option.getAttribute('data-image');
        const text = option.textContent;
        const optionDiv = document.createElement('div');
        const geomtypeAttr = option.getAttribute('geomtype');
        optionDiv.classList.add('select-option');

        if (value.endsWith('.svg')) {
            optionDiv.innerHTML = `<img src="${value}" alt="${text}"> ${text}`;
        } else {
            optionDiv.innerHTML = `<img src="${value}" alt="${text}"> ${text}`;
        }

        optionDiv.setAttribute('data-value', option.value);
        optionDiv.setAttribute('geomtype', geomtypeAttr);

        optionDiv.addEventListener('click', () => {
            this.selectedValue = option.value;
            this.select.value = this.selectedValue;
            this.selectBox.innerHTML = optionDiv.innerHTML;
            this.selectOptions.style.display = 'none';
            const toHighlightId = '__bn-tool-' + optionDiv.getAttribute('geomtype');
            this.highlightGroup(document.getElementById(toHighlightId));

        });

        return optionDiv;
    }

    addEventListeners() {
        this.selectBox.addEventListener('click', () => {
            this.selectOptions.style.display = this.selectOptions.style.display === 'none' ? 'block' : 'none';
            this.searchInput.value = '';
            this.filterOptions();
        });

        this.select.addEventListener('change', () => {
            const selectedValue = this.select.value;
            const selectedOption = this.selectOptions.querySelector(`[data-value="${selectedValue}"]`);
            if (selectedOption) {
                this.selectedValue = selectedValue;
                this.selectBox.innerHTML = selectedOption.innerHTML;
            }
        });

        window.addEventListener('click', (e) => {
            if (!this.target.contains(e.target)) {
                this.selectOptions.style.display = 'none';
            }
        });
    }

    getSelected() {
        return this.selectedValue;
    }

    addOptions(newOptions) {
        newOptions.forEach((optionData) => {
            const option = document.createElement('option');
            option.value = optionData.value;
            option.setAttribute('data-image', optionData.image);
            option.textContent = optionData.text;
            option.setAttribute('geomtype', optionData.geomtype);
            this.select.appendChild(option);

            const optionDiv = this.createOptionDiv(option);
            this.selectOptions.appendChild(optionDiv);
        });
    }

    highlightGroup(groupElem) {
        if (groupElem) {
            groupElem.classList.add('highlight-animation');
    
            // Remove the class after the animation completes
            groupElem.addEventListener('animationend', function() {
                groupElem.classList.remove('highlight-animation');
            }, {once: true});
        }
    }
    
}

export { CustomSelect };
